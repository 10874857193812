import { CharacterSheetNavigationKey } from "../hooks/use-app-routing";
import { CharacterInfo } from "../character/info";
import CharacterDice from "../character/dice";
import CharacterFightView from "../character/fight-view";
import { CharacterMoney } from "../character/money";
import { CharacterInventory } from "../character/inventory";
import { CharacterSkills } from "../character/skills";
import { CharacterArmor } from "../character/armor";
import { CharacterWeapons } from "../character/weapons";
import { CharacterSpells } from "../character/spells";
import { CharacterInjuries } from "../character/injuries";
import { CharacterNotes } from "../character/notes";
import { CharacterMap } from "../character/map";
import { CharacterQuests } from "../character/quests";
import CharacterAcquaintances from "../character/acquaintances";

function getSheetNavigationComponent(
  selectedKey: CharacterSheetNavigationKey
): React.ReactNode {
  switch (selectedKey) {
    case "character-infos":
      return <CharacterInfo />;
    case "character-dice":
      return <CharacterDice />;
    case "character-fight":
      return <CharacterFightView />;
    case "character-money":
      return <CharacterMoney />;
    case "character-inventory":
      return <CharacterInventory />;
    case "character-skills":
      return <CharacterSkills />;
    case "character-armor":
      return <CharacterArmor />;
    case "character-weapons":
      return <CharacterWeapons />;
    case "character-spells":
      return <CharacterSpells />;
    case "character-injuries":
      return <CharacterInjuries />;
    case "notes-list":
      return <CharacterNotes />;
    case "notes-map":
      return <CharacterMap />;
    case "notes-quests":
      return <CharacterQuests />;
    case "notes-acquaintances":
      return <CharacterAcquaintances />;
    default:
      assertNever(selectedKey);
  }
}

function assertNever(selectedKey: never) {
  throw new Error("Function not implemented.");
}

export default getSheetNavigationComponent;
