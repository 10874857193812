import {
  Button,
  Checkbox,
  Form,
  Input,
  Layout,
  notification,
  Typography,
} from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { useAppContext } from "../App";

const { Title } = Typography;
const { useNotification } = notification;

export const LoginPage = () => {
  const { client, setToken, routing } = useAppContext();
  const [api, contextHolder] = useNotification();

  if (!client) {
    return <div>Loading...</div>;
  }

  if (client.isAuthenticated) {
    routing.appNavigationRouteMap["character-select"].navigate();
  }

  return (
    <>
      {contextHolder}
      <Layout style={{ height: "100vh" }}>
        <Layout.Content
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Form<{ username: string; password: string }>
            name="login"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            initialValues={{ remember: true }}
            onFinish={async (values) => {
              try {
                const token = await client.createToken(
                  values.username,
                  values.password
                );
                setToken(token);
              } catch (error: unknown) {
                api.error({
                  message: "Fehler",
                  description:
                    "Beim Anmelden ist ein Fehler aufgetreten. Bitte überprüfe deine Anmeldedaten.",
                  icon: <WarningOutlined style={{ color: "#ff0000" }} />,
                });
                return; // do not continue
              }

              // navigate to character select page
              routing.appNavigationRouteMap["character-select"].navigate();
            }}
            style={{ alignContent: "left", width: "60%" }}
            autoComplete="off"
          >
            <Title level={3}>Login</Title>
            <Form.Item
              label="Benutzername"
              labelAlign="left"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Bitte gib deinen Benutzernamen ein!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              labelAlign="left"
              label="Passwort"
              name="password"
              rules={[
                { required: true, message: "Bitte gib dein Passwort ein!" },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="remember"
              valuePropName="checked"
              wrapperCol={{ offset: 2, span: 22 }}
            >
              <Checkbox>Anmeldedaten merken</Checkbox>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 2, span: 22 }}>
              <Button type="primary" htmlType="submit">
                Anmelden
              </Button>
            </Form.Item>
          </Form>
        </Layout.Content>
      </Layout>
    </>
  );
};
