export type Money =
  | {
      gold: number;
      silver: number;
      copper: number;
    }
  | {
      copper: number;
    };

export const isFullMoney = (
  value: Money
): value is { gold: number; silver: number; copper: number } => {
  return (
    (value as { gold: number; silver: number; copper: number }).gold !==
    undefined
  );
};

export type FunctionalMoney = ReturnType<typeof money>;

const money = (value: Money) => {
  return {
    toCopper() {
      return isFullMoney(value)
        ? value.gold * 100 + value.silver * 10 + value.copper
        : value.copper;
    },
    toObject() {
      const copper = Math.abs(this.toCopper());
      const factor = this.isDebt() ? -1 : 1;

      return {
        gold: Math.floor(copper / 100) * factor,
        silver: Math.floor((copper % 100) / 10) * factor,
        copper: (copper % 10) * factor,
      };
    },
    transferObject(other: Money) {
      const otherMoney = money(other);
      const diff = this.toCopper() + otherMoney.toCopper();
      return money({ copper: diff });
    },
    isDebt() {
      return this.toCopper() < 0;
    },
    abs() {
      return money({ copper: Math.abs(this.toCopper()) });
    },
    invert() {
      return money({ copper: this.toCopper() * -1 });
    },
    toHumanReadable() {
      const moneyObject = this.toObject();
      const { gold, silver, copper } = moneyObject;
      const goldString = gold ? `${gold}g` : "";
      const silverString = silver ? `${silver}s` : "";
      const copperString = copper ? `${copper}c` : "";

      return `${goldString} ${silverString} ${copperString}`.trim();
    },
  };
};

export default money;
