import { Card, Col, Divider, Row, Space, Typography } from "antd";
import { DiceCountInput, DiceDisplay } from "../components/count-input-dice";
import { useState } from "react";

const CharacterDice = ({ title }: { title?: string }) => {
  const [diceCount, setDiceCount] = useState(1);
  return (
    <>
      <Card title={title && <Typography.Title level={4}>{title}</Typography.Title>}>
        <Row>
          <Col span={24} style={{ textAlign: "center" }}>
            <Space>
              <Typography.Text strong>
                Anzahl{" "}
              </Typography.Text>
              <DiceCountInput onCountChange={setDiceCount} />
            </Space>
          </Col>
          <Divider />
          <Col span={24}>
            <DiceDisplay count={diceCount} />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default CharacterDice;
