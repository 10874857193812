import React, { useMemo } from "react";
import { CharacterInfoType } from "../hooks/use-r4s-client";
import {
  ManOutlined,
  WomanOutlined,
  KeyOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { List, Space, Typography } from "antd";
import { useCharacterRequestsContext } from ".";
import styled, { keyframes } from "styled-components";

type CharacterInfoDiplayProps = {
  [key in keyof CharacterInfoType]?: {
    label: string;
    renderAsString?: boolean;
    formatValue?: (value: any) => JSX.Element;
  };
};

const INFO_DISPLAY_PROPS: CharacterInfoDiplayProps = {
  name: { label: "Name" },
  age: { label: "Alter" },
  appearance: { label: "Aussehen" },
  gender: {
    renderAsString: true,
    label: "Geschlecht",
    formatValue: (value: any) => {
      return (
        <span>
          {value === "MALE" ? (
            <ManOutlined />
          ) : value === "FEMALE" ? (
            <WomanOutlined />
          ) : (
            <KeyOutlined />
          )}
        </span>
      );
    },
  },
  origin: { label: "Herkunft" },
};

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledList = styled(List)`
  animation: ${fadeIn} 0.5s;
`;

export const CharacterInfo: React.FC = () => {
  const { useGetInfo } = useCharacterRequestsContext();
  const { data: characterInfos } = useGetInfo();
  const characterInfoElements = useMemo(() => {
    if (!characterInfos) return [];

    return Object.keys(INFO_DISPLAY_PROPS).reduce((acc, key, _index) => {
      const displayPropsKey = key as keyof CharacterInfoDiplayProps;
      const characterValue: any = characterInfos[displayPropsKey];

      if (!characterValue || !INFO_DISPLAY_PROPS[displayPropsKey]) return acc;

      const { label, formatValue, renderAsString } =
        INFO_DISPLAY_PROPS[displayPropsKey]!;
      const value = formatValue ? formatValue(characterValue) : characterValue;

      return [...acc, { label, value, renderAsString }];
    }, [] as { label: string; value: JSX.Element; renderAsString?: boolean }[]);
  }, [characterInfos]);

  return (
    <>
      {!characterInfos ? (
        <LoadingOutlined />
      ) : (
        <StyledList>
          {characterInfoElements.map(({ label, value, renderAsString }) => (
            <List.Item key={label}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Typography.Title level={4}>{label}</Typography.Title>
                {renderAsString ? (
                  <Typography.Text strong>{value}</Typography.Text>
                ) : (
                  value
                )}
              </Space>
            </List.Item>
          ))}
        </StyledList>
      )}
    </>
  );
};
