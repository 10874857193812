import {
  Button,
  Form,
  FormInstance,
  Input,
  Layout,
  notification,
  Select,
  Slider,
  Typography,
} from "antd";
import { useRef } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useUser } from "../hooks/use-user";
import { useAppContext } from "../App";

const { useNotification } = notification;
const { Title } = Typography;

export type CreateCharacterArgs = {
  name: string;
  age: number;
  appearance: string;
  origin: string;
  gender: "M" | "F" | "D";
};

export const CreateCharacterPage: React.FC = () => {
  const [api, contextHolder] = useNotification();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const formRef = useRef<FormInstance>(null);

  const { client } = useAppContext();
  const { useCreateCharacter } = useUser(client);
  const { mutateAsync: createCharacter } = useCreateCharacter({
    onError: (_error) => {
      api.error({
        message: "Fehler",
        description:
          "Beim Erstellen des Charakters ist ein Fehler aufgetreten.",
      });
    },
    onSuccess: async () => {
      api.success({
        message: "Charakter erstellt",
        description: (
          <>
            Dein Charakter wurde erfolgreich erstellt.
            <Button
              type="default"
              onClick={() => {
                api.destroy();
                navigate("/app/character");
              }}
            >
              Profil anzeigen
            </Button>
          </>
        ),
      });
      await queryClient.invalidateQueries("characters");
      formRef.current?.resetFields();
    },
  });

  return (
    <>
      {contextHolder}
      <Layout>
        <Title level={3}>Charakter erstellen</Title>
        <Layout.Content
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Form<CreateCharacterArgs>
            name="create-character"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            style={{ width: "60%" }}
            initialValues={{ remember: true }}
            onFinish={createCharacter}
            autoComplete="off"
            ref={formRef}
          >
            <Form.Item
              label="Name"
              labelAlign="left"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Bitte gib deinem Charakter einen Namen!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Alter"
              labelAlign="left"
              name="age"
              rules={[
                {
                  required: true,
                  message: "Bitte gib das Alter deines Charakters an!",
                },
              ]}
            >
              <Slider
                min={1}
                max={100}
                marks={{
                  1: "1",
                  18: "18",
                  30: "30",
                  40: "40",
                  50: "50",
                  60: "60",
                  70: "70",
                  80: "80",
                  90: "90",
                  100: "100",
                }}
              />
            </Form.Item>

            <Form.Item
              labelAlign="left"
              label="Erscheinungsbild"
              name="appearance"
              rules={[
                {
                  required: true,
                  message: "Beschreibe das Aussehen deines Charakters!",
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              labelAlign="left"
              label="Herkunft"
              name="origin"
              rules={[
                {
                  required: true,
                  message: "Beschreibe die Herkunft deines Charakters!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              labelAlign="left"
              label="Geschlecht"
              name="gender"
              rules={[
                {
                  required: true,
                  message: "Bitte gib das Geschlecht deines Charakters an!",
                },
              ]}
            >
              <Select placeholder="Geschlecht">
                <Select.Option value="M">Männlich</Select.Option>
                <Select.Option value="F">Weiblich</Select.Option>
                <Select.Option value="D">Divers</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 2, span: 22 }}>
              <Button type="primary" htmlType="submit">
                Charakter erstellen
              </Button>
            </Form.Item>
          </Form>
        </Layout.Content>
      </Layout>
    </>
  );
};
