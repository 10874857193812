import { CharacterGenericPageLayout } from "../components/generic-layout";
import { Layout, theme } from "antd";
import { CharacterSheetNavigationKey } from "../hooks/use-app-routing";
import getSheetNavigationComponent from "./get-sheet-navigation-component";
import { useMemo } from "react";

const CharacterViewer: React.FC<{
  selectedKey: CharacterSheetNavigationKey;
}> = ({ selectedKey }) => {
  const {
    token: { colorBgContainer: colorBgBase },
  } = theme.useToken();

  const selectedCharacterSheetComponent: React.ReactNode = useMemo(
    () => getSheetNavigationComponent(selectedKey),
    [selectedKey]
  );

  return (
    <Layout.Content
      style={{
        padding: 0,
        margin: 0,
        background: colorBgBase,
        minHeight: 280,
        maxHeight: "calc(100vh - 130px)",
        overflowX: "hidden",
      }}
    >
      <CharacterGenericPageLayout>
        {selectedCharacterSheetComponent}
      </CharacterGenericPageLayout>
    </Layout.Content>
  );
};

export default CharacterViewer;
