import { Form, FormInstance, Input, Modal } from "antd";
import { FC, useRef } from "react";
import { useCharacterRequestsContext } from "../character";

type MutateAcquaintanceModalProps =
  | {
      onCancel: CallableFunction;
      afterFinish: CallableFunction;
      initalValues?: { name: string; description: string };
      mode: "add";
    }
  | {
      onCancel: CallableFunction;
      afterFinish: CallableFunction;
      initalValues: { name: string; description: string };
      mode: "edit";
      acquaintanceId: string;
    };

const isUpdateProps = (
  props: MutateAcquaintanceModalProps
): props is MutateAcquaintanceModalProps & {
  initalValues: { name: string; description: string };
  mode: "edit";
  itemId: string;
} => {
  return props.mode === "edit";
};

const MutateAcquaintanceModal: FC<MutateAcquaintanceModalProps> = (props) => {
  const { onCancel, afterFinish, initalValues, mode } = props;
  const { useAddCharacterAcquaintance, useUpdateCharacterAcquaintance } =
    useCharacterRequestsContext();
  const { mutate: addAcquaintance } = useAddCharacterAcquaintance();
  const { mutate: updateAcquaintance } = useUpdateCharacterAcquaintance();
  const formRef = useRef<FormInstance>(null);

  return (
    <Modal
      open={true}
      onCancel={() => {
        onCancel();
        formRef.current?.resetFields();
      }}
      title={
        mode === "add"
          ? `Bekanntschaft hinzufügen`
          : `${initalValues?.name} bearbeiten`
      }
      onOk={() => {
        formRef.current?.submit();
      }}
    >
      {
        <Form<{
          name: string;
          description: string;
        }>
          ref={formRef}
          onFinish={(args) => {
            if (isUpdateProps(props)) {
              updateAcquaintance({
                args,
                acquaintanceId: props.acquaintanceId,
              });
            } else {
              addAcquaintance(args);
              formRef.current?.resetFields();
            }
            afterFinish();
          }}
          initialValues={initalValues}
        >
          <Form.Item label="Name">
            <Form.Item name="name" noStyle>
              <Input />
            </Form.Item>
          </Form.Item>
          <Form.Item label="Beschreibung">
            <Form.Item name="description" noStyle>
              <Input.TextArea />
            </Form.Item>
          </Form.Item>
        </Form>
      }
    </Modal>
  );
};

export default MutateAcquaintanceModal;
