import {
  Checkbox,
  Form,
  FormInstance,
  Input,
  Modal,
  Select,
  Space,
} from "antd";
import { FC, useRef, useState } from "react";
import { useCharacterRequestsContext } from "../character";

type AddArmorItemModalProps = {
  open: boolean;
  onCancel: CallableFunction;
  afterFinish: CallableFunction;
  initalValues?: { name: string; description: string };
};

const AddArmorItemModal: FC<AddArmorItemModalProps> = (props) => {
  const { open, onCancel, afterFinish, initalValues } = props;
  const { useAddItemToArmor } = useCharacterRequestsContext();
  const { mutate: addItemToArmor } = useAddItemToArmor();
  const formRef = useRef<FormInstance>(null);
  const [armorPart, setArmorPart] = useState<
    "HEAD" | "BODY" | "LEGS" | "ARMS" | "HANDS" | "SPECIAL"
  >("HEAD");

  return (
    <Modal
      open={open}
      title="Rüstungsitem hinzufügen"
      onCancel={() => {
        onCancel();
        formRef.current?.resetFields();
      }}
      onOk={() => {
        formRef.current?.submit();
      }}
    >
      {
        <Form<{
          name: string;
          armorPoints: number;
          armorPart: string;
          isMagical: string;
        }>
          ref={formRef}
          onFinish={(payload) => {
            addItemToArmor({
              name: payload.name,
              armorPart: armorPart,
              armorPoints: Number(payload.armorPoints),
              isMagical: payload.isMagical,
            });
            afterFinish();
          }}
          initialValues={initalValues}
        >
          <Form.Item label="Typ">
            <Form.Item name="armorPart" noStyle>
              <Select
                options={[
                  { label: "🙂 Kopf", value: "HEAD" },
                  { label: "🥋 Körper", value: "BODY" },
                  { label: "🦿 Beine", value: "LEGS" },
                  { label: "🦾 Arme", value: "ARMS" },
                  { label: "✋ Hände", value: "HANDS" },
                  { label: "✨ Spezial", value: "SPECIAL" },
                ]}
                value={armorPart}
                onChange={(value) => {
                  setArmorPart(value as any);
                }}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item label="Name" name="name">
            <Input />
          </Form.Item>
          <Space size={5}>
            <Form.Item label="Rüstungswert" name="armorPoints">
              <Input />
            </Form.Item>
            <Form.Item
              name="isMagical"
              valuePropName="checked"
              wrapperCol={{ offset: 8, span: 16 }}
            >
              <Checkbox>Magisch?</Checkbox>
            </Form.Item>
          </Space>
        </Form>
      }
    </Modal>
  );
};

export default AddArmorItemModal;
