import { Space, Card, Typography, Row, Col, theme } from "antd";
import React, { useMemo } from "react";
import { NavigationMenuKey } from "../hooks/use-character-page-routes";
import {
  CharacterNavigationKey,
  Navigator,
  NotesNavigationKey,
} from "../hooks/use-app-routing";
import { motion } from "framer-motion";
import { useAppContext } from "../App";

export const CharacterSheetNavigationMenu = ({
  characterId,
}: {
  characterId: string;
}) => {
  const {
    routing: {
      currentNavigationMetadata,
      characterNavigationRouteMap,
      notesNavigationRouteMap,
    },
  } = useAppContext();

  if (!currentNavigationMetadata.characterId) {
    return null;
  }

  return (
    <>
      <Space
        direction="vertical"
        size="large"
        style={{ width: "100%", marginTop: 5 }}
      >
        <CharacterNavigationSubMenu
          title="Charakter"
          characterId={characterId}
          items={
            characterNavigationRouteMap[currentNavigationMetadata.characterId]
          }
        />
        <CharacterNavigationSubMenu
          title="Notizen"
          characterId={characterId}
          items={notesNavigationRouteMap[currentNavigationMetadata.characterId]}
        />
      </Space>
    </>
  );
};

const CharacterSheetNavigationSubTitle: React.FC<{
  label: string;
}> = ({ label }) => {
  return (
    <Card bordered={false} style={{ width: "100%", paddingLeft: 24 }}>
      <Typography.Title level={2}>{label}</Typography.Title>
    </Card>
  );
};

const NAVIGATIONMENUKEY_ICON_MAP: {
  [key in CharacterNavigationKey | NotesNavigationKey]: React.ReactNode;
} = {
  "character-armor": "🛡️",
  "character-dice": "🎲",
  "character-fight": "⚔️",
  "character-infos": "📝",
  "character-injuries": "💔",
  "character-inventory": "🎒",
  "character-money": "💰",
  "character-skills": "🎓",
  "character-spells": "🔮",
  "character-weapons": "🗡️",
  "notes-acquaintances": "👥",
  "notes-list": "📝",
  "notes-map": "🗺️",
  "notes-quests": "📜",
};

export const CharacterNavigationSubMenu = ({
  title,
  characterId,
  items,
}: {
  title: string;
  characterId: string;
  items:
    | {
        [key in CharacterNavigationKey]: Navigator;
      }
    | {
        [key in NotesNavigationKey]: Navigator;
      };
}) => {
  const {
    routing: { getNavigationContext },
  } = useAppContext();
  const navigationMenuItems = useMemo(
    () =>
      Object.entries(items).map(([key, navigator]) => ({
        label: getNavigationContext({
          key: key as NavigationMenuKey,
          characterId,
        }).label,
        navigator,
        icon: NAVIGATIONMENUKEY_ICON_MAP[key as NavigationMenuKey],
        disabled: (
          [
            "character-weapons",
            "notes-map",
            "notes-list",
          ] as NavigationMenuKey[]
        ).includes(key as NavigationMenuKey),
      })),
    [getNavigationContext, items, characterId]
  );

  return (
    <>
      <Row>
        <CharacterSheetNavigationSubTitle label={title} />
      </Row>
      <Row style={{ padding: 5 }}>
        {navigationMenuItems.map((navigationMenuItem, index) => (
          <Col
            key={index}
            style={{ padding: 5 }}
            xs={24}
            sm={12}
            lg={8}
            xl={6}
            xxl={4}
          >
            <CharacterSheetNavigationMenuItem {...navigationMenuItem} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export const CharacterSheetNavigationMenuItem = ({
  label,
  navigator,
  icon,
  disabled,
}: {
  label: string;
  navigator: Navigator;
  icon?: React.ReactNode;
  disabled?: boolean;
}) => {
  const {
    token: { colorTextDisabled },
  } = theme.useToken();
  return (
    <motion.div
      whileHover={{
        scale: disabled ? undefined : 1.03,
        boxShadow: "0px 0px 8px rgba(0,32,0,0.2)",
      }}
      whileTap={{ scale: disabled ? 0.98 : 0.95 }}
      onClick={disabled ? undefined : navigator.navigate}
      style={{ cursor: "pointer" }}
    >
      <Card bordered={false}>
        <Row>
          <Col span={20}>
            <Space direction="horizontal">
              <Typography.Title
                level={4}
                style={{ color: disabled ? colorTextDisabled : undefined }}
              >
                {icon} {label}
              </Typography.Title>
            </Space>
          </Col>
        </Row>
      </Card>
    </motion.div>
  );
};
