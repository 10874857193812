import useNotification from "antd/es/notification/useNotification";
import { CharacterRequestsContext } from "../context-factory/character-requests-context";
import { useCharacterRequests } from "../hooks/use-character-requests";
import { CharacterSheetNavigationKey } from "../hooks/use-app-routing";
import CharacterViewer from "./viewer";

export const {
  Provider: CharacterRequestsContextProvider,
  useValue: useCharacterRequestsContext,
} = CharacterRequestsContext;

const CharacterSheet: React.FC<{
  selectedKey: CharacterSheetNavigationKey;
  characterId: string;
}> = ({ selectedKey, characterId }) => {
  const [notify, contextHolder] = useNotification();
  const characterRequests = useCharacterRequests(characterId, notify);

  if (!characterId) {
    return <></>;
  }

  return (
    <>
      {/* TODO: move contextHolder into root app component */}
      {contextHolder}
      <CharacterRequestsContextProvider value={characterRequests}>
        {/* <CharacterSideBar
          characterId={characterId}
          disable={["notes-map", "notes-list", "character-weapons"]}
        /> */}
        <CharacterViewer selectedKey={selectedKey} />
      </CharacterRequestsContextProvider>
    </>
  );
};

export default CharacterSheet;
