import { Form, FormInstance, Input, Modal } from "antd";
import { FC, useRef } from "react";
import { useCharacterRequestsContext } from "../../character";

type MutateItemContainerModalProps = {
  open: boolean;
  onCancel: CallableFunction;
  afterFinish: CallableFunction;
  initialValues?: { name: string };
} & ({ mode: "add" } | { mode: "edit"; itemId: string });

const isUpdateProps = (
  props: MutateItemContainerModalProps
): props is MutateItemContainerModalProps & {
  mode: "edit";
  itemId: string;
} => {
  return props.mode === "edit";
};

const MutateItemContainerModal: FC<MutateItemContainerModalProps> = (props) => {
  const { open, onCancel, afterFinish, initialValues, mode } = props;
  const { useAddItemContainer, useUpdateItemContainer } =
    useCharacterRequestsContext();
  const { mutate: addItemContainer } = useAddItemContainer();
  const { mutate: updateItemContainer } = useUpdateItemContainer();
  const formRef = useRef<FormInstance>(null);

  return (
    <Modal
      open={open}
      onCancel={() => onCancel()}
      title={mode === "add" ? "Inventar hinzufügen" : "Inventar bearbeiten"}
      onOk={() => formRef.current?.submit()}
    >
      {
        <Form<{ name: string }>
          ref={formRef}
          onFinish={(payload) => {
            if (isUpdateProps(props)) {
              updateItemContainer({ id: props.itemId, payload });
            } else {
              addItemContainer(payload.name);
            }

            afterFinish();
            formRef.current?.resetFields();
          }}
          initialValues={initialValues}
        >
          <Form.Item label="Name">
            <Form.Item name="name" noStyle>
              <Input />
            </Form.Item>
          </Form.Item>
        </Form>
      }
    </Modal>
  );
};

export default MutateItemContainerModal;
