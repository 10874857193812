import { Breadcrumb, Button, Layout, Menu, theme } from "antd";
import { useAppContext } from "../App";
import ProfilePopOver from "../components/profile-popover";
import { PlusOutlined, SelectOutlined } from "@ant-design/icons";
import { AppNavigationKey } from "../hooks/use-app-routing";
import { useEffect } from "react";

const { Header } = Layout;

export const AppContent: React.FC = () => {
  const { client, user } = useAppContext();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const CHARACTER_SELECTION_MENUKEY: AppNavigationKey = "character-select";
  const CHARACTER_CREATE_MENUKEY: AppNavigationKey = "character-create";
  const ADMIN_MENUKEY: AppNavigationKey = "admin";

  const {
    routing: {
      appNavigationRouteMap,
      getNavigationContext,
      routesNode,
      currentNavigationMetadata,
    },
  } = useAppContext();

  useEffect(() => {
    if (!client.isAuthenticated) {
      appNavigationRouteMap.login.navigate();
    }
  }, [appNavigationRouteMap, client.isAuthenticated]);

  return (
    <>
      <ProfilePopOver />
      <Layout
        style={{ margin: 0, minHeight: "100vh", background: colorBgContainer }}
      >
        {client.isAuthenticated && (
          <>
            <Header className="header" style={{ padding: 0, width: "100%" }}>
              <Menu
                theme="dark"
                mode="horizontal"
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  zIndex: 99,
                  width: "100vw",
                }}
                onSelect={({ key }) => {
                  appNavigationRouteMap[key as AppNavigationKey].navigate();
                }}
              >
                <Menu.SubMenu key="character-play" title="Charakter">
                  <Menu.Item key={CHARACTER_SELECTION_MENUKEY}>
                    <SelectOutlined /> Auswählen
                  </Menu.Item>
                  <Menu.Item key={CHARACTER_CREATE_MENUKEY}>
                    <PlusOutlined /> Neu
                  </Menu.Item>
                </Menu.SubMenu>
                {user?.role === "ADMIN" && (
                  <Menu.Item key={ADMIN_MENUKEY}>Admin</Menu.Item>
                )}
              </Menu>
            </Header>

            <Breadcrumb style={{ margin: "16px 0" }}>
              {getNavigationContext(currentNavigationMetadata).breadcrumbs?.map(
                (item, index) => (
                  <Breadcrumb.Item key={index}>
                    <Button
                      type="link"
                      onClick={item.navigator.navigate}
                      disabled={!item.navigator}
                    >
                      {item.label}
                    </Button>
                  </Breadcrumb.Item>
                )
              )}
            </Breadcrumb>
          </>
        )}
        <Layout>{routesNode}</Layout>
      </Layout>
    </>
  );
};
