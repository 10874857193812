import { Avatar } from "antd";
import React from "react";
import { Button } from "antd";
import { Popover } from "antd";
import { Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useAppContext } from "../App";

const ProfilePopOver: React.FC = () => {
  const { user, client, setToken } = useAppContext();

  if (!client.isAuthenticated) {
    return null;
  }

  return (
    <div style={{ position: "fixed", top: 16, right: 20, zIndex: 100 }}>
      <Space direction="vertical">
        <Popover
          title={user?.profile.name || "User"}
          content={
            <Button type="text" onClick={() => setToken(null)}>
              Logout
            </Button>
          }
          align={{
            targetOffset: [0, 0],
            points: ["tr", "br"],
            offset: [0, 0],
          }}
        >
          <Avatar icon={<UserOutlined />} />
        </Popover>
      </Space>
    </div>
  );
};

export default ProfilePopOver;
