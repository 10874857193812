import { Row, Layout } from "antd";
import React, { PropsWithChildren } from "react";

const { Content } = Layout;

export const CharacterGenericPageLayout: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <>
      <Row>
        <Content
          style={{
            overflowY: "auto",
            margin: 0,
            minHeight: "calc(100vh - 280px)",
          }}
        >
          {children}
        </Content>
      </Row>
    </>
  );
};
