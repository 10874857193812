import {
  Button,
  Card,
  Divider,
  List,
  Modal,
  Space,
  Tooltip,
  Typography,
} from "antd";
import {
  ExpandOutlined as ContainerFilled,
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  StepBackwardOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { useCharacterRequestsContext } from ".";
import InventoryContentTable from "../components/inventory/inventory-content-table";
import MutateItemContainerModal from "../components/inventory/inventory-mutate-container-modal";
import MutateItemModal from "../components/inventory/inventory-mutate-item-modal";

export const CharacterInventory: React.FC = () => {
  const {
    useGetItemContainerList,
    useGetItemContainer,
    useDeleteItemContainer,
    useDeleteItem,
  } = useCharacterRequestsContext();
  const { data: inventoryContainerList } = useGetItemContainerList();
  const { mutate: deleteItemContainer } = useDeleteItemContainer();

  const [openInventoryContainerId, setOpenInventoryContainerId] = useState<
    string | undefined
  >(undefined);
  const { mutate: deleteItem } = useDeleteItem(openInventoryContainerId);

  const { data: openInventoryContainer } = useGetItemContainer(
    openInventoryContainerId
  );

  const [addItemToContainerId, setAddItemToContainerId] = useState<
    string | undefined
  >(undefined);

  const [itemToEditId, setItemToEditId] = useState<string | undefined>(
    undefined
  );
  const [itemToDeleteId, setItemToDeleteId] = useState<string | undefined>(
    undefined
  );

  const [addItemContainerModalOpen, setAddItemContainerModalOpen] =
    useState(false);

  const [containerToEditId, setContainerToEditId] = useState<
    string | undefined
  >(undefined);

  return (
    <>
      {!openInventoryContainerId && (
        <>
          <List
            bordered={false}
            grid={{
              xs: 1,
              sm: 1,
              md: 1,
              lg: 2,
              xl: 3,
              xxl: 3,
            }}
            style={{ overflowY: "auto", height: "calc(100vh - 260px)" }}
            dataSource={inventoryContainerList || []}
            renderItem={(item) => (
              <List.Item style={{ padding: 0 }}>
                <Card bordered={true} style={{ margin: 3 }}>
                  <Typography.Title level={3}>{item?.name}</Typography.Title>
                  <Divider />
                  <Button.Group>
                    <Tooltip title="Inventar öffnen">
                      <Button
                        onClick={() => setOpenInventoryContainerId(item.id)}
                        type="primary"
                      >
                        <ContainerFilled />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Behälter bearbeiten">
                      <Button
                        type="primary"
                        onClick={() => setContainerToEditId(item.id)}
                      >
                        <EditOutlined />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Behälter löschen">
                      <Button
                        type="primary"
                        danger
                        onClick={() => deleteItemContainer(item.id)}
                      >
                        <DeleteOutlined />
                      </Button>
                    </Tooltip>
                  </Button.Group>
                </Card>
              </List.Item>
            )}
          />
          <Button
            type="primary"
            shape="round"
            size="large"
            icon={<PlusOutlined />}
            onClick={() => setAddItemContainerModalOpen(true)}
            style={{
              position: "fixed",
              right: 20,
              bottom: 10,
              zIndex: 100,
            }}
          >
            Behälter hinzufügen
          </Button>
          <MutateItemContainerModal
            open={addItemContainerModalOpen}
            onCancel={() => setAddItemContainerModalOpen(false)}
            afterFinish={() => setAddItemContainerModalOpen(false)}
            mode="add"
          />
          {containerToEditId && (
            <MutateItemContainerModal
              open={containerToEditId !== undefined}
              onCancel={() => setContainerToEditId(undefined)}
              afterFinish={() => setContainerToEditId(undefined)}
              mode="edit"
              initialValues={{
                name:
                  inventoryContainerList?.find(
                    (c) => c.id === containerToEditId
                  )?.name || "",
              }}
              itemId={containerToEditId}
            />
          )}
        </>
      )}

      {openInventoryContainerId && (
        <>
          <div style={{ overflowX: "auto", height: "calc(100vh - 270px)" }}>
            <Typography.Title level={3}>
              {openInventoryContainer?.name}
            </Typography.Title>
            <InventoryContentTable
              data={openInventoryContainer?.items || []}
              onDeleted={setItemToDeleteId}
              onEdit={setItemToEditId}
            />
          </div>
          <Space
            direction="horizontal"
            style={{
              right: 20,
              bottom: 10,
              zIndex: 100,
              position: "fixed",
            }}
          >
            <Button
              type="default"
              shape="round"
              size="large"
              icon={<StepBackwardOutlined />}
              onClick={() => setOpenInventoryContainerId(undefined)}
            >
              Zurück
            </Button>

            <Button
              type="primary"
              shape="round"
              size="large"
              icon={<PlusOutlined />}
              onClick={() => setAddItemToContainerId(openInventoryContainerId)}
            >
              Item hinzufügen
            </Button>
          </Space>
        </>
      )}
      {addItemToContainerId && (
        <MutateItemModal
          inventoryContainer={inventoryContainerList?.find(
            ({ id }) => id === addItemToContainerId
          )}
          onCancel={() => setAddItemToContainerId(undefined)}
          afterFinish={() => setAddItemToContainerId(undefined)}
          mode="add"
        />
      )}
      {itemToEditId && (
        <MutateItemModal
          afterFinish={() => setItemToEditId(undefined)}
          inventoryContainer={openInventoryContainer}
          onCancel={() => setItemToEditId(undefined)}
          mode="edit"
          itemId={itemToEditId}
          initalValues={openInventoryContainer?.items.find(
            (item: any) => item.id === itemToEditId
          )}
        />
      )}
      {itemToDeleteId && (
        <Modal
          title={"Item löschen"}
          open={itemToDeleteId !== undefined}
          onOk={() => {
            deleteItem(itemToDeleteId);
            setItemToDeleteId(undefined);
          }}
          onCancel={() => setItemToDeleteId(undefined)}
        >
          <Typography.Paragraph>
            {`Möchtest du ${
              openInventoryContainer?.items.find(
                (item: any) => item.id === itemToDeleteId
              )?.name
            } löschen?`}
          </Typography.Paragraph>
        </Modal>
      )}
    </>
  );
};
