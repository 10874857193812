import { useCallback, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAppContext } from "../App";
import { MonetaryAmount } from "./use-r4s-client";
import { NotificationInstance } from "antd/es/notification/interface";
import money, { Money } from "../utils/money";
import { CreateSkill } from "../character/skills";
import { isAxiosError } from "axios";

export type CharacterRequests = ReturnType<typeof useCharacterRequests>;

export const useCharacterRequests = (
  characterId: string | undefined,
  notify?: NotificationInstance
) => {
  const { client } = useAppContext();
  const queryClient = useQueryClient();

  const onError = useCallback(
    async (err: unknown) => {
      if (notify && isAxiosError(err)) {
        notify.error({
          message: err.response?.data?.message || "Unbekannter Fehler",
        });
      }
    },
    [notify]
  );

  return useMemo(
    () => ({
      useGetQuests: () =>
        useQuery(["characters", characterId, "quests"], async () =>
          characterId ? await client?.getCharacterQuests(characterId) : null
        ),
      useAddQuest: () =>
        useMutation(
          ["characters", characterId, "quests"],
          async (quest: {
            name: string;
            description: string;
            rewardMoney?: MonetaryAmount;
            reward?: string;
          }) =>
            characterId
              ? await client?.addCharacterQuest(characterId, quest)
              : null,
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries([
                "characters",
                characterId,
                "quests",
              ]);
            },
            onError,
          }
        ),
      useGetMoney: () =>
        useQuery(["characters", characterId, "money"], async () =>
          characterId ? await client?.getCharacterMoney(characterId) : null
        ),
      usePatchMoney: (moneyDiff: Money) =>
        useMutation(
          ["characters", characterId, "money", moneyDiff],
          async () =>
            characterId
              ? await client?.patchCharacterMoney(
                  characterId,
                  money(moneyDiff).toObject()
                )
              : null,
          {
            onSuccess: async () => {
              notify?.destroy();
              notify?.success({
                message: money(moneyDiff).isDebt()
                  ? `${money(moneyDiff).abs().toHumanReadable()} abgezogen!`
                  : `${money(moneyDiff).toHumanReadable()} hinzugefügt!`,
                duration: 10,
              });
              await queryClient.invalidateQueries([
                "characters",
                characterId,
                "money",
              ]);
            },
            onError,
          }
        ),
      useGetInfo: () =>
        useQuery(["characters", characterId], async () =>
          characterId ? await client?.getCharacter(characterId) : null
        ),
      useGetSkills: () =>
        useQuery(["characters", characterId, "skills"], async () =>
          characterId ? await client?.getCharacterSkills(characterId) : null
        ),
      useIncrementSkillPoints: () =>
        useMutation(
          ["characters", characterId, "skillpoints", "increment"],
          async (skillpoints: number) => {
            if (!characterId) return null;

            await client?.incrementCharacterSkillPoints(
              characterId,
              skillpoints
            );
          },
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries(["characters", characterId]);
              await queryClient.invalidateQueries([
                "characters",
                characterId,
                "skills",
              ]);
            },
            onError,
          }
        ),
      useUpdateStatus: (
        status: "ACTIVE" | "COMPLETED" | "FAILED" | "DISCARDED"
      ) =>
        useMutation(
          ["characters", characterId, "status", status],
          async (data: { questId: string; money?: MonetaryAmount }) => {
            if (!characterId) return null;

            await client?.patchCharacterQuestStatus(
              characterId,
              data.questId,
              status
            );
            if (status === "COMPLETED") {
              notify?.success({
                message: "Quest abgeschlossen!",
              });

              if (!data.money) return;

              const reward = money({
                gold: data.money?.gold || 0,
                silver: data.money?.silver || 0,
                copper: data.money?.copper || 0,
              }).toObject();

              await client?.patchCharacterMoney(characterId, reward);
              notify?.success({
                message: `${reward.gold} Gold, ${reward.silver} Silber und ${reward.copper} Kupfer wurden hinzufgefügt!`,
              });
            }
          },
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries(["characters", characterId]);
              await queryClient.invalidateQueries([
                "characters",
                characterId,
                "money",
              ]);
              await queryClient.invalidateQueries([
                "characters",
                characterId,
                "quests",
              ]);
            },
          }
        ),
      useAddSkill: () =>
        useMutation(
          ["characters", characterId, "skills"],
          async (skill: CreateSkill) =>
            characterId
              ? await client?.addCharacterSkill(characterId, skill)
              : null,
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries([
                "characters",
                characterId,
                "skills",
              ]);
            },
            onError,
          }
        ),
      useDeleteSkill: (skillId: string) =>
        useMutation(
          ["characters", characterId, "skills", skillId],
          async () =>
            characterId
              ? await client?.deleteCharacterSkill(characterId, skillId)
              : null,
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries([
                "characters",
                characterId,
                "skills",
              ]);
            },
            onError,
          }
        ),
      useGetItemContainerList: () =>
        useQuery(["characters", characterId, "itemContainers"], async () =>
          characterId
            ? await client?.getCharacterItemContainerList(characterId)
            : null
        ),
      useGetItemContainer: (containerId: string | undefined) =>
        useQuery(
          ["characters", characterId, "itemContainers", containerId],
          async () =>
            characterId && containerId
              ? await client?.getCharacterItemContainer(
                  characterId,
                  containerId
                )
              : null
        ),
      useAddItemContainer: () =>
        useMutation(
          ["characters", characterId, "itemContainers"],
          async (name: string) =>
            characterId
              ? await client?.addCharacterItemContainer(characterId, name)
              : null,
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries([
                "characters",
                characterId,
                "itemContainers",
              ]);
            },
            onError,
          }
        ),
      useAddItem: (containerId: string | undefined) =>
        useMutation(
          ["characters", characterId, "itemContainers", containerId, "items"],
          async ({
            name,
            description,
          }: {
            name: string;
            description?: string;
          }) =>
            characterId && containerId
              ? await client?.addItemToCharacterItemContainer(
                  characterId,
                  containerId,
                  {
                    name,
                    description,
                  }
                )
              : null,
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries([
                "characters",
                characterId,
                "itemContainers",
              ]);
            },
          }
        ),
      useUpdateItem: (containerId: string | undefined) =>
        useMutation(
          ["characters", characterId, "itemContainers", containerId, "items"],
          async (args: {
            id: string;
            payload: Partial<{
              name: string;
              description: string;
            }>;
          }) =>
            characterId && containerId
              ? await client?.updateItemContainerItem(
                  characterId,
                  containerId,
                  args.id,
                  args.payload
                )
              : null,
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries([
                "characters",
                characterId,
                "itemContainers",
              ]);
            },
            onError,
          }
        ),
      useDeleteItemContainer: () =>
        useMutation(
          ["characters", characterId, "itemContainers"],
          async (containerId: string | undefined) =>
            characterId && containerId
              ? await client?.deleteItemContainer(characterId, containerId)
              : null,
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries([
                "characters",
                characterId,
                "itemContainers",
              ]);
            },
            onError,
          }
        ),
      useDeleteItem: (containerId: string | undefined) =>
        useMutation(
          ["characters", characterId, "itemContainers", containerId, "items"],
          async (itemId: string) =>
            characterId && containerId
              ? await client?.deleteItemContainerItem(
                  characterId,
                  containerId,
                  itemId
                )
              : null,
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries([
                "characters",
                characterId,
                "itemContainers",
              ]);
            },
            onError,
          }
        ),
      useUpdateItemContainer: () =>
        useMutation(
          ["characters", characterId, "itemContainers"],
          async (args: {
            id: string;
            payload: Partial<{
              name: string;
            }>;
          }) =>
            characterId
              ? await client?.updateItemContainer(
                  characterId,
                  args.id,
                  args.payload
                )
              : null,
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries([
                "characters",
                characterId,
                "itemContainers",
              ]);
            },
            onError,
          }
        ),
      useGetMagicStones: () =>
        useQuery(["characters", characterId, "magicStones"], async () =>
          characterId
            ? await client?.getCharacterMagicStones(characterId)
            : null
        ),
      useAddMagicStone: () =>
        useMutation(
          ["characters", characterId, "magicStones"],
          async (magicStone: any) =>
            characterId
              ? await client?.addCharacterMagicStone(characterId, magicStone)
              : null,
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries([
                "characters",
                characterId,
                "magicStones",
              ]);
            },
          }
        ),
      usePatchMagicStoneSpellslotsUsed: () =>
        useMutation(
          ["characters", characterId, "magicStones"],
          async (args: { magicStoneId: string; diff: number }) =>
            characterId
              ? await client?.patchCharacterMagicStoneSpellslotsUsed(
                  characterId,
                  args.magicStoneId,
                  args.diff
                )
              : null,
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries([
                "characters",
                characterId,
                "magicStones",
              ]);
            },
            onError,
          }
        ),
      useDeleteMagicStone: () =>
        useMutation(
          ["characters", characterId, "magicStones"],
          async (magicStoneId: string) =>
            characterId
              ? await client?.deleteCharacterMagicStone(
                  characterId,
                  magicStoneId
                )
              : null,
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries([
                "characters",
                characterId,
                "magicStones",
              ]);
            },
            onError,
          }
        ),
      usePatchCharacterDivineSpellsUsed: () =>
        useMutation(
          ["characters", characterId, "divineSpells"],
          async (args: { diff: number }) =>
            characterId
              ? await client?.patchCharacterDivineSpellsUsed(
                  characterId,
                  args.diff
                )
              : null,
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries(["characters", characterId]);
            },
          }
        ),
      useGetInjuries: () =>
        useQuery(["characters", characterId, "injuries"], async () =>
          characterId ? await client?.getCharacterInjuries(characterId) : null
        ),
      usePatchInjuries: () =>
        useMutation(
          ["characters", characterId, "injuries"],
          async (args: any) =>
            characterId
              ? await client?.patchCharacterInjuries(characterId, args)
              : null,
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries(["characters", characterId]);
            },
            onError,
          }
        ),
      useGetPermanentInjuries: () =>
        useQuery(["characters", characterId, "permanentInjuries"], async () =>
          characterId
            ? await client?.getCharacterPermanentInjuries(characterId)
            : null
        ),
      usePatchPermanentInjury: () =>
        useMutation(
          ["characters", characterId, "permanentInjuries"],
          async ({ injuryId, args }: { injuryId: string; args: any }) =>
            characterId
              ? await client?.patchCharacterPermanentInjury(
                  characterId,
                  injuryId,
                  args
                )
              : null,
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries(["characters", characterId]);
            },
          }
        ),
      useAddPermanentInjury: () =>
        useMutation(
          ["characters", characterId, "permanentInjuries"],
          async (args: any) =>
            characterId
              ? await client?.addCharacterPermanentInjury(characterId, args)
              : null,
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries(["characters", characterId]);
            },
          }
        ),
      useDeletePermanentInjury: () =>
        useMutation(
          ["characters", characterId, "permanentInjuries"],
          async (injuryId: string) =>
            characterId
              ? await client?.deleteCharacterPermanentInjury(
                  characterId,
                  injuryId
                )
              : null,
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries(["characters", characterId]);
            },
          }
        ),
      useGetArmor: () =>
        useQuery(["characters", characterId, "armor"], async () =>
          characterId ? await client?.getCharacterArmor(characterId) : null
        ),
      useAddItemToArmor: () =>
        useMutation(
          ["characters", characterId, "armor"],
          async (args: any) =>
            characterId
              ? await client?.addItemToCharacterArmor(characterId, args)
              : null,
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries(["characters", characterId]);
            },
            onError,
          }
        ),
      useDeleteItemFromArmor: () =>
        useMutation(
          ["characters", characterId, "armor"],
          async (itemId: string) =>
            characterId
              ? await client?.removeItemFromCharacterArmor(characterId, itemId)
              : null,
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries([
                "characters",
                characterId,
                "armor",
              ]);
            },
          }
        ),
      useGetCharacterAcquaintances: () =>
        useQuery(["characters", characterId, "acquaintances"], async () =>
          characterId
            ? await client?.getCharacterAcquantainces(characterId)
            : null
        ),
      useAddCharacterAcquaintance: () =>
        useMutation(
          ["characters", characterId, "acquaintances"],
          async (args: any) =>
            characterId
              ? await client?.addCharacterAcquantaince(characterId, args)
              : null,
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries(["characters", characterId]);
            },
            onError,
          }
        ),
      useDeleteCharacterAcquaintance: () =>
        useMutation(
          ["characters", characterId, "acquaintances"],
          async (acquaintanceId: string) =>
            characterId
              ? await client?.deleteCharacterAcquantaince(
                  characterId,
                  acquaintanceId
                )
              : null,
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries(["characters", characterId]);
            },
            onError,
          }
        ),
      useUpdateCharacterAcquaintance: () =>
        useMutation(
          ["characters", characterId, "acquaintances"],
          async ({
            acquaintanceId,
            args,
          }: {
            acquaintanceId: string;
            args: any;
          }) =>
            characterId
              ? await client?.patchCharacterAcquantaince(
                  characterId,
                  acquaintanceId,
                  args
                )
              : null,
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries(["characters", characterId]);
            },
            onError,
          }
        ),
      useGenerateCharacterSkillDescription: (skillName: string) =>
        useMutation(
          ["characters", "skills", "description"],
          async (skillName: string) =>
            await client?.generateSkillDescription(skillName),
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries([
                "characters",
                "skills",
                "description",
              ]);
            },
            onError,
          }
        ),
    }),
    [characterId, client, queryClient, notify, onError]
  );
};
