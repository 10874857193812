import { useCharacterRequestsContext } from ".";
import { FC, useMemo, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Row,
  Typography,
  theme,
} from "antd";
import {
  PlusOutlined,
  MinusOutlined,
  UndoOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import MutatePermanentInjuryModal from "../components/injuries-mutate-permanent-injury";

export const CharacterInjuries: FC = () => {
  const {
    useGetInjuries,
    usePatchInjuries,
    useGetPermanentInjuries,
    useDeletePermanentInjury,
    usePatchPermanentInjury,
  } = useCharacterRequestsContext();

  const { data: characterInjuries } = useGetInjuries();
  const { data: permanentInjuries } = useGetPermanentInjuries();
  const { mutate: patchPermanentInjury } = usePatchPermanentInjury();
  const { mutate: patchInjuries } = usePatchInjuries();
  const { mutate: deletePermanentInjury } = useDeletePermanentInjury();

  const {
    token: { colorFill: permanentInjuryColor },
  } = theme.useToken();

  const patchInjuriesActions = useMemo<(() => void)[]>(() => {
    return permanentInjuries
      ?.map(
        (permanentInjury: {
          isIncurable: boolean;
          daysHealed: number;
          daysUntilHealed: number;
          id: string;
        }) => {
          if (
            permanentInjury?.isIncurable === false &&
            permanentInjury?.daysHealed !== permanentInjury?.daysUntilHealed
          ) {
            return () =>
              patchPermanentInjury({
                injuryId: permanentInjury?.id,
                args: {
                  daysHealed: permanentInjury?.daysHealed + 1,
                },
              });
          } else {
            return undefined;
          }
        }
      )
      .filter(
        (action: undefined | (() => void)) => action !== undefined
      ) as (() => void)[];
  }, [patchPermanentInjury, permanentInjuries]);

  const [isAddPermanentInjuryModalOpen, setIsAddPermanentInjuryModalOpen] =
    useState(false);

  return (
    <>
      <Card bordered={false}>
        <Row>
          <Col md={12} xs={24} style={{ padding: 5 }}>
            <Row>
              <Col xs={24}>
                <Typography.Title level={4}>
                  Tödliche Verletzungen: {characterInjuries?.lethal} / 3
                </Typography.Title>
              </Col>
              <Col xs={24}>
                <Button.Group size="middle">
                  <Button
                    disabled={
                      !characterInjuries || characterInjuries?.lethal === 3
                    }
                    onClick={() =>
                      patchInjuries({ lethal: characterInjuries.lethal + 1 })
                    }
                    type="dashed"
                  >
                    <PlusOutlined />
                  </Button>
                  <Button
                    disabled={
                      !characterInjuries || characterInjuries?.lethal === 0
                    }
                    onClick={() => {
                      patchInjuries({ lethal: characterInjuries.lethal - 1 });
                    }}
                    type="dashed"
                  >
                    <MinusOutlined />
                  </Button>
                  <Button onClick={() => patchInjuries({ lethal: 0 })}>
                    <UndoOutlined />
                  </Button>
                </Button.Group>
              </Col>
            </Row>
          </Col>
          <Col md={12} xs={24} style={{ padding: 5 }}>
            <Row>
              <Col xs={24}>
                <Typography.Title level={4}>
                  Nicht tödliche Verletzungen: {characterInjuries?.nonLethal} /
                  9
                </Typography.Title>
              </Col>
              <Col xs={24}>
                <Button.Group size="middle">
                  <Button
                    disabled={
                      !characterInjuries || characterInjuries?.nonLethal === 9
                    }
                    onClick={() =>
                      patchInjuries({
                        nonLethal: characterInjuries.nonLethal + 1,
                      })
                    }
                    type="dashed"
                  >
                    <PlusOutlined />
                  </Button>
                  <Button
                    disabled={
                      !characterInjuries || characterInjuries?.nonLethal === 0
                    }
                    onClick={() => {
                      patchInjuries({
                        nonLethal: characterInjuries.nonLethal - 1,
                      });
                    }}
                    type="dashed"
                  >
                    <MinusOutlined />
                  </Button>
                  <Button onClick={() => patchInjuries({ nonLethal: 0 })}>
                    <UndoOutlined />
                  </Button>
                </Button.Group>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col xs={24} style={{ padding: 5 }}>
            <Row>
              <Col xs={24}>
                <Typography.Title level={4}>
                  Permanente Verletzungen
                </Typography.Title>
              </Col>
              <Col
                xs={24}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Row style={{ width: "100%" }}>
                  {permanentInjuries?.map((permanentInjury: any) => (
                    <Col xs={24} md={12} style={{ padding: 5 }}>
                      <Badge.Ribbon
                        color={"red"}
                        text="Permanent"
                        style={{
                          visibility:
                            permanentInjury?.isIncurable === true
                              ? "visible"
                              : "collapse",
                        }}
                      >
                        <Card
                          bordered={true}
                          style={{
                            width: "100%",
                            backgroundColor: permanentInjuryColor,
                          }}
                        >
                          <Row>
                            <Col xs={24}>
                              <Typography.Title level={4}>
                                {permanentInjury?.description}
                              </Typography.Title>
                            </Col>
                            {permanentInjury?.isIncurable === true ? null : (
                              <>
                                <Col xs={24}>
                                  <Typography.Paragraph>
                                    Tage vergangen bis zur Heilung:{" "}
                                    {permanentInjury?.daysHealed} /{" "}
                                    {permanentInjury?.daysUntilHealed}
                                  </Typography.Paragraph>
                                </Col>
                              </>
                            )}
                            <Col xs={24}>
                              <Button.Group size="middle">
                                <Button
                                  onClick={() =>
                                    deletePermanentInjury(permanentInjury?.id)
                                  }
                                >
                                  <DeleteOutlined />
                                </Button>
                              </Button.Group>
                            </Col>
                          </Row>
                        </Card>
                      </Badge.Ribbon>
                    </Col>
                  ))}
                </Row>
                <MutatePermanentInjuryModal
                  mode="add"
                  afterFinish={() => setIsAddPermanentInjuryModalOpen(false)}
                  onCancel={() => setIsAddPermanentInjuryModalOpen(false)}
                  permanentInjury={{} as any}
                  isOpen={isAddPermanentInjuryModalOpen}
                />
              </Col>
            </Row>
            <Row style={{ width: "100%" }}>
              <Col xs={24}>
                <Button.Group size="middle">
                  <Button
                    disabled={patchInjuriesActions?.length === 0}
                    onClick={() => {
                      patchInjuriesActions.forEach((patchInjuriesAction) => {
                        patchInjuriesAction();
                      });
                    }}
                    type="dashed"
                  >
                    <PlusOutlined /> Tag vergangen
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      setIsAddPermanentInjuryModalOpen(true);
                    }}
                  >
                    <PlusOutlined /> Neu
                  </Button>
                </Button.Group>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
};
