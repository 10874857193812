function toRoman(num: number): string {
  if (num < 0 || num > 3999) {
    throw new Error("Invalid number. The number must be between 1 and 3999.");
  }

  if (num === 0) {
    return "-";
  }

  let roman = "";
  const romanNumeralMap: [number, string][] = [
    [1000, "M"],
    [900, "CM"],
    [500, "D"],
    [400, "CD"],
    [100, "C"],
    [90, "XC"],
    [50, "L"],
    [40, "XL"],
    [10, "X"],
    [9, "IX"],
    [5, "V"],
    [4, "IV"],
    [1, "I"],
  ];

  for (let i = 0; i < romanNumeralMap.length; i++) {
    const [value, symbol] = romanNumeralMap[i];

    while (num >= value) {
      roman += symbol;
      num -= value;
    }
  }

  return roman;
}

export default toRoman;
