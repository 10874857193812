import React, { PropsWithChildren } from "react";

const createContext = <T extends Record<string, unknown>>(
  defaultValue: T
): {
  Provider: React.FC<PropsWithChildren<{ value: T }>>;
  useValue: () => T;
} => {
  const Context = React.createContext<T>(defaultValue);
  const Provider = ({ value, children }: PropsWithChildren<{ value: T }>) => {
    return <Context.Provider value={value}>{children}</Context.Provider>;
  };
  const useValue = () => React.useContext<T>(Context);
  return {
    Provider,
    useValue,
  };
};
export default createContext;
