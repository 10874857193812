import {
  Button,
  Card,
  Divider,
  Form,
  FormInstance,
  Input,
  List,
  Modal,
  Space,
  Typography,
  Row,
  Col,
} from "antd";
import { ComponentProps, useCallback, useMemo, useRef, useState } from "react";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteRowOutlined,
  PlusOutlined,
  RestOutlined,
} from "@ant-design/icons";

import { MoneyInput } from "../components/money-input";
import { MonetaryAmount } from "../hooks/use-r4s-client";
import money from "../utils/money";
import { MoneyBagde } from "../components/money-badge";
import { useCharacterRequestsContext } from ".";

const PAGE_SIZE = 5;

export type CreateQuestForm = {
  name: string;
  description: string;
  rewardMoney?: MonetaryAmount;
  reward?: string;
};

export const CharacterQuests: React.FC = () => {
  const { useGetQuests, useAddQuest, useUpdateStatus } =
    useCharacterRequestsContext();
  const { data: quests } = useGetQuests();
  const { mutateAsync: addQuest } = useAddQuest();

  const [currentPage, setCurrentPage] = useState(1);
  const visibleItems = useMemo<Array<any>>(
    () =>
      quests?.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE) ??
      [],
    [currentPage, quests]
  );

  const paginationConfig: ComponentProps<typeof List>["pagination"] =
    useMemo(() => {
      if (visibleItems.length === 0) return false;
      if (!quests || quests.length <= PAGE_SIZE) return undefined;

      return {
        onChange: setCurrentPage,
        pageSize: PAGE_SIZE,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
        total: quests.length,
      };
    }, [quests, visibleItems]);
  const [isAddQuestModalVisible, setAddQuestModalVisible] = useState(false);
  const formRef = useRef<FormInstance>(null);
  const { mutate: discardQuest } = useUpdateStatus("DISCARDED");
  const { mutate: completeQuest } = useUpdateStatus("COMPLETED");
  const { mutate: resetQuest } = useUpdateStatus("ACTIVE");

  return (
    <>
      <List
        bordered={false}
        grid={{ gutter: 16, xs: 24 }}
        pagination={paginationConfig}
        style={{
          width: "100%",
          overflowY: "auto",
          height: "calc(100vh - 260px)",
        }}
      >
        {visibleItems.map(
          (
            { name, description, copperReward: copper, id, status, reward },
            _index
          ) => {
            const rewardMoney = money({ copper }).toObject();

            return (
              <List.Item style={{ padding: 0 }}>
                <Card
                  bordered={false}
                  style={{ textAlign: "left", width: "100%" }}
                >
                  <Space direction="horizontal" size={15}>
                    {status === "COMPLETED" ? (
                      <CheckCircleFilled style={{ color: "green" }} />
                    ) : status === "DISCARDED" ? (
                      <CloseCircleFilled style={{ color: "red" }} />
                    ) : null}
                    <Typography.Title
                      level={4}
                      style={{
                        textDecoration:
                          status === "COMPLETED" || status === "DISCARDED"
                            ? "line-through"
                            : "none",
                      }}
                    >
                      {`${name}${
                        status === "COMPLETED"
                          ? " (abgeschlossen)"
                          : status === "DISCARDED"
                          ? " (abgebrochen)"
                          : ""
                      }`}
                    </Typography.Title>
                  </Space>
                  <Typography.Paragraph>{description}</Typography.Paragraph>
                  <Divider />
                  {money(rewardMoney).toCopper() !== 0 && (
                    <>
                      <Row>
                        <Col xs={24} style={{ padding: 15 }}>
                          <Typography.Text>Bezahlung:</Typography.Text>
                        </Col>
                        <Col xs={24} style={{ paddingLeft: 15 }}>
                          <Space>
                            <MoneyBagde
                              overflowCount={1000000}
                              count={rewardMoney.copper}
                              coinCurrency={"copper"}
                            />
                            <MoneyBagde
                              overflowCount={1000000}
                              count={rewardMoney.silver}
                              coinCurrency={"silver"}
                            />
                            <MoneyBagde
                              overflowCount={1000000}
                              count={rewardMoney.gold}
                              coinCurrency={"gold"}
                            />
                          </Space>
                        </Col>
                      </Row>
                      <Divider />
                    </>
                  )}
                  {reward && (
                    <>
                      <Row>
                        <Col style={{ padding: 15 }} xs={24}>
                          <Typography.Text>Belohnung:</Typography.Text>
                          <Typography.Paragraph>{reward}</Typography.Paragraph>
                        </Col>
                      </Row>
                      <Divider />
                    </>
                  )}
                  <Row>
                    {status !== "COMPLETED" && (
                      <Col
                        style={{
                          padding: 5,
                          textAlign: "right",
                        }}
                        xs={24}
                      >
                        <Button
                          type="primary"
                          onClick={() => {
                            completeQuest({
                              questId: id,
                              money: rewardMoney,
                            });
                          }}
                        >
                          <CheckCircleFilled /> Quest abschließen
                        </Button>
                      </Col>
                    )}
                    {status !== "DISCARDED" && status !== "COMPLETED" && (
                      <Col
                        xs={24}
                        style={{
                          padding: 5,
                          textAlign: "right",
                        }}
                      >
                        <Button
                          type="dashed"
                          onClick={() => {
                            discardQuest({ questId: id });
                          }}
                        >
                          <DeleteRowOutlined /> Quest abbrechen
                        </Button>
                      </Col>
                    )}
                    {status !== "ACTIVE" && (
                      <Col
                        xs={24}
                        style={{
                          padding: 5,
                          textAlign: "right",
                        }}
                      >
                        <Button
                          type="dashed"
                          onClick={() => {
                            resetQuest({ questId: id });
                          }}
                        >
                          <RestOutlined /> Quest zurücksetzen
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Card>
              </List.Item>
            );
          }
        )}
      </List>
      <Modal
        title="Quest hinzufügen"
        open={isAddQuestModalVisible}
        onOk={() => {
          formRef?.current?.submit();
        }}
        onCancel={() => {
          setAddQuestModalVisible(false);
        }}
        width={800}
      >
        <Form<CreateQuestForm>
          ref={formRef}
          onFinish={(values) => {
            addQuest(values);
            setAddQuestModalVisible(false);
          }}
        >
          <Form.Item label="Name">
            <Form.Item name="name" noStyle>
              <Input />
            </Form.Item>
          </Form.Item>
          <Form.Item label="Beschreibung">
            <Form.Item name="description" noStyle>
              <Input.TextArea />
            </Form.Item>
          </Form.Item>
          <Form.Item label="Belohnung">
            <Form.Item name="reward" noStyle>
              <Input />
            </Form.Item>
          </Form.Item>
          <Form.Item label="Belohnung Geld" name="rewardMoney">
            <MoneyFormField />
          </Form.Item>
        </Form>
      </Modal>
      <Button
        type="primary"
        shape="circle"
        icon={<PlusOutlined />}
        size="large"
        style={{
          position: "fixed",
          right: 40,
          bottom: 20,
          zIndex: 100,
        }}
        onClick={() => {
          setAddQuestModalVisible(true);
        }}
      />
    </>
  );
};

interface MoneyInputProps {
  value?: MonetaryAmount;
  onChange?: (value: MonetaryAmount) => void;
}

const MoneyFormField: React.FC<MoneyInputProps> = ({
  value = {},
  onChange,
}) => {
  const [wallet, setWallet] = useState<MonetaryAmount>({
    gold: 0,
    silver: 0,
    copper: 0,
  });

  const changeWallet = useCallback(
    (newValue: MonetaryAmount) => {
      setWallet(money(newValue).toObject());
      onChange?.(value ? { ...value, ...newValue } : newValue);
    },
    [onChange, value]
  );

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <MoneyInput
        value={wallet}
        setValue={changeWallet}
        currency={"gold"}
        moneyInputButtonTypes={[-10, -1, "reset", 1, 10]}
      />
      <MoneyInput
        value={wallet}
        setValue={changeWallet}
        currency={"silver"}
        moneyInputButtonTypes={[-1, "reset", 1]}
      />
      <MoneyInput
        value={wallet}
        setValue={changeWallet}
        currency={"copper"}
        moneyInputButtonTypes={[-1, "reset", 1]}
      />
    </Space>
  );
};
