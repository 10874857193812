import { CharacterSkills } from "./skills";
import { CharacterSpells } from "./spells";
import { CharacterInjuries } from "./injuries";
import CharacterDice from "./dice";

const CharacterFightView = () => {
  const gapSize = 3;
  return (
    <>
      <div style={{ marginBottom: gapSize }}>
        <CharacterSkills />
      </div>
      <div style={{ marginBottom: gapSize }}>
        <CharacterInjuries />
      </div>
      <div style={{ marginBottom: gapSize }}>
        <CharacterDice title={"Würfel"} />
      </div>
      <div style={{ marginBottom: gapSize }}>
        <CharacterSpells />
      </div>
    </>
  );
};

export default CharacterFightView;
