import {
  Route,
  RouteProps,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { LoginPage } from "../pages/login";
import { useCallback, useMemo } from "react";
import { ProfilePage } from "../pages/profile";
import { AdminPage } from "../pages/admin";
import { CreateCharacterPage } from "../components/create-character";
import {
  isCharacterNavigationMenuKey,
  isNotesNavigationMenuKey,
} from "./use-character-page-routes";
import CharacterSheet from "../character";
import { CharacterSheetNavigationMenu } from "../character/sheet-navigation-menu";
import { PathnameMenukeyMatcher } from "../utils/pathname-menukey-matcher";

export type AppNavigationKey =
  | "admin"
  | "character-create"
  | "character-select"
  | "admin"
  | "login";

export type CharacterSheetNavigationRootKey = "character-sheet";

export type CharacterNavigationKey =
  | "character-infos"
  | "character-dice"
  | "character-fight"
  | "character-money"
  | "character-inventory"
  | "character-skills"
  | "character-armor"
  | "character-weapons"
  | "character-spells"
  | "character-injuries";

export type NotesNavigationKey =
  | "notes-list"
  | "notes-map"
  | "notes-quests"
  | "notes-acquaintances";

export type CharacterSheetNavigationKey =
  | CharacterNavigationKey
  | NotesNavigationKey;

export type NavigationMenuKey =
  | AppNavigationKey
  | CharacterSheetNavigationRootKey
  | CharacterSheetNavigationKey;

export type NavigationMenuMap = {
  rootElements: Record<AppNavigationKey, string>;
  children: {
    [key in CharacterSheetNavigationRootKey]: string;
  };
};

export type Navigator = {
  navigate: () => void;
};

export type AppRoute = Required<Pick<RouteProps, "path" | "element">>;
export type LabeledNavigator = Navigator & { label: string };
export type R4SRoute = AppRoute & LabeledNavigator;

export type NavigationKeyMetaData =
  | { key: AppNavigationKey; characterId: null }
  | {
      key:
        | CharacterNavigationKey
        | NotesNavigationKey
        | CharacterSheetNavigationRootKey;
      characterId: string;
    };
export type NavigationContext = {
  label: string;
  parent: NavigationKeyMetaData | null;
  breadcrumbs: Array<Omit<NavigationContext, "breadcrumbs">>;
  navigator: Navigator;
} & NavigationKeyMetaData;

export type AppRouting = ReturnType<typeof useAppRouting>;

export const useAppRouting = (characters?: { id: string; name: string }[]) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const availableCharacterIds = useMemo(
    () => characters?.map(({ id }) => id) || [],
    [characters]
  );

  const buildNavigator = useCallback(
    (path: string): Navigator => ({ navigate: () => navigate(path) }),
    [navigate]
  );

  const getLabel = useCallback(
    (metadata: NavigationKeyMetaData): string => {
      switch (metadata.key) {
        case "admin":
          return "Admin";
        case "character-create":
          return "Charakter erstellen";
        case "character-select":
          return "Charakter wählen";
        case "login":
          return "Login";
        case "character-sheet":
          return (
            characters?.find((c) => c.id === metadata.characterId)?.name ||
            "Charakter"
          );
        case "character-infos":
          return "Infos";
        case "character-dice":
          return "Würfel";
        case "character-fight":
          return "Kampf";
        case "character-money":
          return "Geld";
        case "character-inventory":
          return "Inventar";
        case "character-skills":
          return "Fertigkeiten";
        case "character-armor":
          return "Rüstung";
        case "character-weapons":
          return "Waffen";
        case "character-spells":
          return "Zauber";
        case "character-injuries":
          return "Verletzungen";
        case "notes-list":
          return "Notizen";
        case "notes-map":
          return "Karte";
        case "notes-quests":
          return "Quests";
        case "notes-acquaintances":
          return "Bekanntschaften";
      }
    },
    [characters]
  );

  const getCharacterRouteMetaData = useCallback(
    (
      characterId: string,
      key: CharacterNavigationKey | NotesNavigationKey
    ): R4SRoute => {
      const path = `/app/character/${characterId}/${key}`;
      return {
        path,
        label: getLabel({ key, characterId }),
        element: <CharacterSheet characterId={characterId} selectedKey={key} />,
        ...buildNavigator(path),
      };
    },
    [buildNavigator, getLabel]
  );

  const characterNavigationRouteMap: {
    [characterId: string]: {
      [key in CharacterNavigationKey]: R4SRoute;
    };
  } = useMemo(() => {
    return Object.fromEntries(
      availableCharacterIds.reduce((acc, characterId) => {
        acc.push([
          characterId,
          [
            "character-infos",
            "character-dice",
            "character-fight",
            "character-money",
            "character-inventory",
            "character-skills",
            "character-armor",
            "character-weapons",
            "character-spells",
            "character-injuries",
          ].reduce((routeMap, key) => {
            const characterNavigationKey = key as CharacterNavigationKey;
            Object.assign(routeMap, {
              [characterNavigationKey]: getCharacterRouteMetaData(
                characterId,
                characterNavigationKey
              ),
            });
            return routeMap;
          }, {} as { [key in CharacterNavigationKey]: R4SRoute }),
        ]);
        return acc;
      }, [] as [string, { [key in CharacterNavigationKey]: R4SRoute }][])
    );
  }, [getCharacterRouteMetaData, availableCharacterIds]);

  const notesNavigationRouteMap: {
    [characterId: string]: {
      [key in NotesNavigationKey]: R4SRoute;
    };
  } = useMemo(() => {
    return Object.fromEntries(
      availableCharacterIds.reduce((acc, characterId) => {
        acc.push([
          characterId,
          [
            "notes-list",
            "notes-map",
            "notes-quests",
            "notes-acquaintances",
          ].reduce((routeMap, key) => {
            const notesNavigationKey = key as NotesNavigationKey;
            Object.assign(routeMap, {
              [notesNavigationKey]: getCharacterRouteMetaData(
                characterId,
                notesNavigationKey
              ),
            });
            return routeMap;
          }, {} as { [key in NotesNavigationKey]: R4SRoute }),
        ]);
        return acc;
      }, [] as [string, { [key in NotesNavigationKey]: R4SRoute }][])
    );
  }, [getCharacterRouteMetaData, availableCharacterIds]);

  const appNavigationRouteMap: {
    [key in AppNavigationKey]: R4SRoute;
  } = useMemo(() => {
    const getAppRouteMetaData = (
      element: JSX.Element,
      path: string
    ): R4SRoute => {
      return {
        path,
        element,
        label: getLabel({
          key: path.split("/")[2] as AppNavigationKey,
          characterId: null,
        }),
        ...buildNavigator(path),
      };
    };
    return {
      admin: getAppRouteMetaData(<AdminPage />, "/app/admin"),
      "character-create": getAppRouteMetaData(
        <CreateCharacterPage />,
        "/app/character/create"
      ),
      "character-select": getAppRouteMetaData(
        <ProfilePage characters={characters || []} />,
        "/app/character"
      ),
      login: getAppRouteMetaData(<LoginPage />, "/login"),
    };
  }, [characters, buildNavigator, getLabel]);

  const characterSheetNavigationRouteMap: {
    [characterId: string]: {
      [key in CharacterSheetNavigationRootKey]: R4SRoute;
    };
  } = useMemo(() => {
    return Object.fromEntries(
      availableCharacterIds.reduce((acc, characterId) => {
        acc.push([
          characterId,
          {
            "character-sheet": {
              path: `/app/character/${characterId}`,
              label: characters?.find((c) => c.id === characterId)?.name || "",
              element: (
                <CharacterSheetNavigationMenu characterId={characterId} />
              ),
              ...buildNavigator(`/app/character/${characterId}`),
            },
          },
        ]);
        return acc;
      }, [] as [string, { [key in CharacterSheetNavigationRootKey]: R4SRoute }][])
    );
  }, [buildNavigator, availableCharacterIds, characters]);

  const getNavigationContext: (
    param: NavigationKeyMetaData
  ) => NavigationContext = useCallback(
    ({ key, characterId }) => {
      const buildNavigationMetaProps = (
        value: NavigationKeyMetaData,
        parent: NavigationKeyMetaData | null,
        navigator: Navigator
      ): NavigationContext => {
        const baseContext = {
          ...value,
          label: getLabel(value),
          parent,
          navigator,
        };

        if (parent === null) {
          return { ...baseContext, parent: null, breadcrumbs: [baseContext] };
        }

        const parentContext = getNavigationContext(parent);
        return {
          ...baseContext,
          parent: parentContext,
          breadcrumbs: [...parentContext.breadcrumbs, baseContext],
        };
      };

      if (characterId === null) {
        return buildNavigationMetaProps(
          { key, characterId: null },
          null,
          buildNavigator(appNavigationRouteMap[key].path)
        );
      }

      if (isCharacterNavigationMenuKey(key)) {
        return buildNavigationMetaProps(
          { key, characterId },
          { key: "character-sheet", characterId },
          characterNavigationRouteMap[characterId][key]
        );
      }

      if (isNotesNavigationMenuKey(key)) {
        return buildNavigationMetaProps(
          { key, characterId },
          { key: "character-sheet", characterId },
          notesNavigationRouteMap[characterId][key]
        );
      }

      if (key === "character-sheet") {
        return buildNavigationMetaProps(
          { key, characterId },
          { key: "character-select", characterId: null },
          buildNavigator(
            characterSheetNavigationRouteMap[characterId]["character-sheet"]
              .path
          )
        );
      }

      throw new Error(`Unexpected value: ${key}`);
    },
    [
      getLabel,
      buildNavigator,
      appNavigationRouteMap,
      characterSheetNavigationRouteMap,
      characterNavigationRouteMap,
      notesNavigationRouteMap,
    ]
  );

  const routesNode = useMemo(() => {
    const allRoutes: AppRoute[] = [
      ...Object.values(appNavigationRouteMap),
      ...Object.values(characterNavigationRouteMap).flatMap(Object.values),
      ...Object.values(notesNavigationRouteMap).flatMap(Object.values),
      ...Object.values(characterSheetNavigationRouteMap).flatMap(Object.values),
    ];

    return (
      <Routes>
        {allRoutes.map((route, index) => (
          <Route key={`route-${index}`} {...route} />
        ))}
        <Route path="*" element={<LoginPage />} />
      </Routes>
    );
  }, [
    appNavigationRouteMap,
    characterNavigationRouteMap,
    notesNavigationRouteMap,
    characterSheetNavigationRouteMap,
  ]);

  const currentNavigationMetadata: NavigationKeyMetaData = useMemo(() => {
    const selectedKey = PathnameMenukeyMatcher.getMenuKey(pathname);
    const selectedCharacterId = PathnameMenukeyMatcher.getCharacterId(pathname);

    if (
      isCharacterNavigationMenuKey(selectedKey) ||
      isNotesNavigationMenuKey(selectedKey) ||
      selectedKey === "character-sheet"
    ) {
      if (!selectedCharacterId) {
        throw new Error(
          `Expected characterId to be defined for key ${selectedKey}`
        );
      }
      return { key: selectedKey, characterId: selectedCharacterId };
    }
    return { key: selectedKey, characterId: null };
  }, [pathname]);

  return {
    appNavigationRouteMap,
    characterSheetNavigationRouteMap,
    characterNavigationRouteMap,
    notesNavigationRouteMap,
    getNavigationContext,
    routesNode,
    currentNavigationMetadata,
  };
};
