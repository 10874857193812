import { useMemo } from "react";
import { useStorage } from "./use-storage";
import jwt_decode from "jwt-decode";

export type DecodedToken = {
  id: number;
  email: string;
  profile: { id: number; name: string; bio: string; userId: number };
  role: "USER" | "ADMIN";
  iat: number;
  exp: number;
};

export type User = Omit<DecodedToken, "iat" | "exp">;

const useAuth = () => {
  const [token, setToken] = useStorage<string | null>(
    "token",
    window.sessionStorage.getItem("token") || null,
    window.sessionStorage
  );
  const user = useMemo<User | null>(() => {
    try {
      if (!token) {
        return null;
      }
      const { iat, exp, ...decodedUser } = jwt_decode<DecodedToken>(token);
      return decodedUser;
    } catch (error: unknown) {
      console.error(error);
      return null;
    }
  }, [token]);

  return {
    token,
    setToken,
    user,
  };
};

export default useAuth;
