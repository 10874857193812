import Axios, { AxiosError } from "axios";
import axiosRetry from "axios-retry";
import { CreateCharacterArgs } from "../components/create-character";
import { CreateSkill } from "../character/skills";
import { CharacterInfoType, MonetaryAmount } from "../hooks/use-r4s-client";

export type R4SConfig = {
  baseURL?: string;
  token: string | null;
};

export type R4SClient = ReturnType<typeof createR4SClient>;

export type MagicStone = {
  spellSlotsUsed: number;
  id: string;
  type: "TURBID" | "CLEAR";
  size: "SMALL" | "MEDIUM" | "LARGE";
};

const createR4SClient = ({ token, baseURL }: R4SConfig) => {
  const client = Axios.create({ baseURL });

  if (token !== null) {
    client.interceptors.request.use(
      (config) => {
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  axiosRetry(client, {
    retries: 10,
    retryDelay(retryCount, error) {
      if (error.response?.status === 429) {
        return 1000 * Math.pow(2, retryCount);
      }
      return 1000;
    },
    retryCondition: (error: AxiosError) => {
      return !!error.response && error.response.status === 500;
    },
  });

  const createToken = async (usernameOrEmail: string, password: string) => {
    const { data } = await client.post("/auth/login", {
      username: usernameOrEmail,
      password,
    });

    if ("access_token" in data && typeof data.access_token === "string") {
      return data.access_token as string;
    }

    throw new Error("Invalid token");
  };

  const signup = async (email: string, password: string, username: string) => {
    const { data } = await client.post("/auth/signup", {
      username: username,
      password: password,
      email: email,
    });
    return data;
  };

  const getProfiles = async () => {
    const { data } = await client.get("/user/profile");
    return data;
  };

  const createCharacter = async (character: CreateCharacterArgs) => {
    const { data } = await client.post("/characters", {
      ...character,
      gender: ["MALE", "FEMALE", "DIVERSE"].find((g) =>
        g.startsWith(character.gender)
      ),
    });
    return data;
  };

  const getCharacters = async () => {
    const { data } = await client.get<{ id: string; name: string }[]>(
      "/characters"
    );
    return data;
  };

  const getCharacter = async (characterId: string) => {
    const { data } = await client.get<CharacterInfoType>(
      `/characters/${characterId}`
    );
    return data;
  };

  const getCharacterMoney = async (characterId: string) => {
    const { data } = await client.get<MonetaryAmount>(
      `/characters/${characterId}/money`
    );
    return data;
  };

  const patchCharacterMoney = async (
    characterId: string,
    moneyDiff: MonetaryAmount
  ) => {
    const { data } = await client.patch(
      `/characters/${characterId}/money`,
      moneyDiff
    );
    return data;
  };

  const getCharacterQuests = async (characterId: string) => {
    const { data } = await client.get(`/characters/${characterId}/quests`);
    return data;
  };

  const addCharacterQuest = async (
    characterId: string,
    quest: {
      name: string;
      description: string;
      rewardMoney?: MonetaryAmount;
      reward?: string;
    }
  ) => {
    const { data } = await client.post(`/characters/${characterId}/quests`, {
      ...quest,
      rewardMoney: {
        gold: quest.rewardMoney?.gold || 0,
        silver: quest.rewardMoney?.silver || 0,
        copper: quest.rewardMoney?.copper || 0,
      },
    });
    return data;
  };

  const patchCharacterQuestStatus = async (
    characterId: string,
    questId: string,
    status: "ACTIVE" | "COMPLETED" | "FAILED" | "DISCARDED"
  ) => {
    const { data } = await client.patch(
      `/characters/${characterId}/quests/${questId}/status/${status}`
    );
    return data;
  };

  const getCharacterSkills = async (characterId: string) => {
    const { data } = await client.get<{
      skills: {
        description: string;
        name: string;
        id: string;
        parentId: string | null;
      }[];
      skillpoints: number;
    }>(`/characters/${characterId}/skills`);
    return data;
  };

  const incrementCharacterSkillPoints = async (
    characterId: string,
    skillPointsDiff: number
  ) => {
    const { data } = await client.patch(
      `/characters/${characterId}/skillpoints`,
      { skillPointsDiff }
    );
    return data;
  };

  const addCharacterSkill = async (characterId: string, skill: CreateSkill) => {
    const { data } = await client.post<CreateSkill>(
      `/characters/${characterId}/skills`,
      skill
    );
    return data;
  };

  const deleteCharacterSkill = async (characterId: string, skillId: string) => {
    const { data } = await client.delete(
      `/characters/${characterId}/skills/${skillId}`
    );

    return data;
  };

  const getCharacterItemContainerList = async (characterId: string) => {
    const { data } = await client.get<
      Array<{
        id: string;
        name: string;
      }>
    >(`/characters/${characterId}/inventory`);

    return data;
  };

  const getCharacterItemContainer = async (
    characterId: string,
    containerId: string
  ) => {
    const { data } = await client.get(
      `/characters/${characterId}/inventory/${containerId}`
    );

    return data;
  };

  const addCharacterItemContainer = async (
    characterId: string,
    containerName: string
  ) => {
    const { data } = await client.post(`/characters/${characterId}/inventory`, {
      name: containerName,
    });

    return data;
  };

  const addItemToCharacterItemContainer = async (
    characterId: string,
    containerId: string,
    item: { name: string; description?: string }
  ) => {
    const { data } = await client.post(
      `/characters/${characterId}/inventory/${containerId}`,
      item
    );

    return data;
  };

  const deleteItemContainer = async (
    characterId: string,
    containerId: string
  ) => {
    const { data } = await client.delete(
      `/characters/${characterId}/inventory/${containerId}`
    );
    return data;
  };

  const deleteItemContainerItem = async (
    characterId: string,
    containerId: string,
    itemId: string
  ) => {
    const { data } = await client.delete(
      `/characters/${characterId}/inventory/${containerId}/${itemId}`
    );
    return data;
  };

  const updateItemContainerItem = async (
    characterId: string,
    containerId: string,
    itemId: string,
    item: Partial<{ name: string; description: string }>
  ) => {
    const { data } = await client.patch(
      `/characters/${characterId}/inventory/${containerId}/${itemId}`,
      item
    );

    return data;
  };

  const updateItemContainer = async (
    characterId: string,
    containerId: string,
    container: Partial<{ name: string }>
  ) => {
    const { data } = await client.patch(
      `/characters/${characterId}/inventory/${containerId}`,
      container
    );

    return data;
  };

  const getCharacterMagicStones = async (characterId: string) => {
    const { data } = await client.get<MagicStone[]>(
      `/characters/${characterId}/magicstones`
    );

    return data;
  };

  const addCharacterMagicStone = async (
    characterId: string,
    magicStone: Omit<MagicStone, "id">
  ) => {
    const { data } = await client.post<MagicStone>(
      `/characters/${characterId}/magicstones`,
      magicStone
    );

    return data;
  };

  const patchCharacterMagicStoneSpellslotsUsed = async (
    characterId: string,
    magicStoneId: string,
    diff: number
  ) => {
    const { data } = await client.patch(
      `/characters/${characterId}/magicstones/${magicStoneId}/spellslotsused`,
      { diff }
    );

    return data;
  };

  const deleteCharacterMagicStone = async (
    characterId: string,
    magicStoneId: string
  ) => {
    const { data } = await client.delete(
      `/characters/${characterId}/magicstones/${magicStoneId}`
    );

    return data;
  };

  const patchCharacterDivineSpellsUsed = async (
    characterId: string,
    diff: number
  ) => {
    const { data } = await client.patch(
      `/characters/${characterId}/divinespellsused`,
      { diff }
    );

    return data;
  };

  const generateSkillDescription = async (skillName: string) => {
    const { data } = await client.get<string>(
      `/ai/skill-description?skillname=${skillName}`
    );

    return data;
  };

  const createUser = async (name: string, password: string, email: string) => {
    const { data } = await client.post("/user", {
      name,
      password,
      email,
    });
    return data;
  };

  const getUserList = async () => {
    const { data } = await client.get("/user");
    return data;
  };

  const deleteUser = async (userId: number) => {
    const { data } = await client.delete(`/user/${userId}`);
    return data;
  };

  const patchUserPassword = async (userId: number, password: string) => {
    const { data } = await client.patch(`/user/${userId}/password`, {
      password,
    });

    return data;
  };

  const getCharacterInjuries = async (characterId: string) => {
    const { data } = await client.get<any>(
      `/characters/${characterId}/injuries`
    );

    return data;
  };

  const patchCharacterInjuries = async (characterId: string, update: any) => {
    const { data } = await client.patch(
      `/characters/${characterId}/injuries`,
      update
    );

    return data;
  };

  const getCharacterPermanentInjuries = async (characterId: string) => {
    const { data } = await client.get(
      `/characters/${characterId}/permanent-injuries`
    );

    return data;
  };

  const patchCharacterPermanentInjury = async (
    characterId: string,
    injuryId: string,
    update: any
  ) => {
    const { data } = await client.patch(
      `/characters/${characterId}/permanent-injuries/${injuryId}`,
      update
    );

    return data;
  };

  const deleteCharacterPermanentInjury = async (
    characterId: string,
    injuryId: string
  ) => {
    const { data } = await client.delete(
      `/characters/${characterId}/permanent-injuries/${injuryId}`
    );

    return data;
  };

  const addCharacterPermanentInjury = async (
    characterId: string,
    injury: any
  ) => {
    const { data } = await client.post(
      `/characters/${characterId}/permanent-injuries`,
      injury
    );

    return data;
  };

  const getCharacterArmor = async (characterId: string) => {
    const { data } = await client.get(`/characters/${characterId}/armor`);

    return data;
  };

  const addItemToCharacterArmor = async (characterId: string, armor: any) => {
    const { data } = await client.post(
      `/characters/${characterId}/armor/items`,
      armor
    );

    return data;
  };

  const removeItemFromCharacterArmor = async (
    characterId: string,
    itemId: string
  ) => {
    const { data } = await client.delete(
      `/characters/${characterId}/armor/items/${itemId}`
    );

    return data;
  };

  const getCharacterAcquaintances = async (characterId: string) => {
    const { data } = await client.get(
      `/characters/${characterId}/acquaintances`
    );

    return data;
  };

  const addCharacterAcquaintance = async (
    characterId: string,
    acquantaince: any
  ) => {
    const { data } = await client.post(
      `/characters/${characterId}/acquaintances`,
      acquantaince
    );

    return data;
  };

  const deleteCharacterAcquaintance = async (
    characterId: string,
    acquantainceId: string
  ) => {
    const { data } = await client.delete(
      `/characters/${characterId}/acquaintances/${acquantainceId}`
    );

    return data;
  };

  const patchCharacterAcquaintance = async (
    characterId: string,
    acquantainceId: string,
    update: any
  ) => {
    const { data } = await client.patch(
      `/characters/${characterId}/acquaintances/${acquantainceId}`,
      update
    );

    return data;
  };

  return {
    isAuthenticated: token !== null,
    getProfiles,
    getCharacters,
    signup,
    createToken,
    createUser,
    getUserList,
    createCharacter,
    getCharacter,
    getCharacterMoney,
    patchCharacterMoney,
    getCharacterQuests,
    addCharacterQuest,
    deleteCharacterSkill,
    patchCharacterQuestStatus,
    incrementCharacterSkillPoints,
    getCharacterSkills,
    addCharacterSkill,
    getCharacterItemContainerList,
    getCharacterItemContainer,
    addCharacterItemContainer,
    addItemToCharacterItemContainer,
    deleteItemContainer,
    deleteItemContainerItem,
    updateItemContainerItem,
    updateItemContainer,
    getCharacterMagicStones,
    addCharacterMagicStone,
    patchCharacterMagicStoneSpellslotsUsed,
    deleteCharacterMagicStone,
    patchCharacterDivineSpellsUsed,
    getCharacterInjuries,
    patchCharacterInjuries,
    deleteUser,
    patchUserPassword,
    getCharacterPermanentInjuries,
    patchCharacterPermanentInjury,
    deleteCharacterPermanentInjury,
    addCharacterPermanentInjury,
    generateSkillDescription,
    getCharacterArmor,
    addItemToCharacterArmor,
    removeItemFromCharacterArmor,
    getCharacterAcquantainces: getCharacterAcquaintances,
    addCharacterAcquantaince: addCharacterAcquaintance,
    deleteCharacterAcquantaince: deleteCharacterAcquaintance,
    patchCharacterAcquantaince: patchCharacterAcquaintance,
  };
};

export default createR4SClient;
