import toRoman from "./toRoman";
import { SkillObject } from "../character/skills";

// recursive function to get children of a skill
const getChildNodes = (
  skill: SkillObject,
  skillData: SkillObject[],
  level: number = 0
): any => {
  const hasChildren = skillData.some((skillObject) => {
    return skillObject.parentId === skill.id;
  });

  if (!hasChildren) return undefined;

  return skillData
    .filter((skillObject) => {
      return skillObject.parentId === skill.id;
    })
    .map((s) => {
      return {
        title: `${toRoman(level + 1)} - ${s.name}`,
        value: s.id,
        key: s.id,
        children: getChildNodes(s, skillData, level + 1),
        level,
      };
    });
};

export default getChildNodes;
