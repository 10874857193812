import { Avatar, Badge } from "antd";
import { ComponentProps } from "react";
import { GoldFilled as CoinIcon } from "@ant-design/icons";
import { MonetaryAmount } from "../hooks/use-r4s-client";

export const MoneyBagde: React.FC<
  ComponentProps<typeof Badge> & { coinCurrency: keyof MonetaryAmount }
> = ({ coinCurrency, ...badgeProps }) => {
  const background: React.CSSProperties["background"] = {
    gold: "gold",
    silver: "silver",
    copper: "orangered",
  }[coinCurrency];

  return (
    <Badge {...badgeProps} offset={[8, 0]}>
      <Avatar icon={<CoinIcon />} style={{ background }} size="large" />
    </Badge>
  );
};
