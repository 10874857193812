import { useMemo } from "react";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { LaptopOutlined, UserOutlined } from "@ant-design/icons";
import { CharacterNavigationKey, NotesNavigationKey } from "./use-app-routing";

export type CharacterNavigationRootKey = "character" | "notes";
export type NavigationElement = {
  label: string;
  icon?: React.ReactNode;
};
export type NavigationMenuMap = {
  rootElements: Record<CharacterNavigationRootKey, NavigationElement>;
  children: { [key in NavigationMenuKey]: NavigationElement };
};

export type NavigationMenuKey = CharacterNavigationKey | NotesNavigationKey;

const NOTES_MENUKEY_COMPONENT_MAP: Record<
  NotesNavigationKey,
  NavigationElement
> = {
  "notes-list": { label: "Notizen" },
  "notes-map": { label: "Karte" },
  "notes-quests": { label: "Quests" },
  "notes-acquaintances": {
    label: "Bekannte",
  },
};

const CHARACTER_MENUKEY_COMPONENT_MAP: Record<
  CharacterNavigationKey,
  NavigationElement
> = {
  "character-infos": { label: "Infos" },
  "character-dice": { label: "Würfel" },
  "character-fight": { label: "Kampf" },
  "character-money": { label: "Geld" },
  "character-inventory": {
    label: "Inventar",
  },
  "character-skills": { label: "Skills" },
  "character-armor": { label: "Rüstung" },
  "character-weapons": { label: "Waffen" },
  "character-spells": { label: "Spellslots" },
  "character-injuries": {
    label: "Verletzungen",
  },
};

export const NAVIGATION_MENU_MAP: NavigationMenuMap = {
  rootElements: {
    character: { label: "Character", icon: <UserOutlined /> },
    notes: { label: "Notes", icon: <LaptopOutlined /> },
  },
  children: {
    ...NOTES_MENUKEY_COMPONENT_MAP,
    ...CHARACTER_MENUKEY_COMPONENT_MAP,
  },
};

export function isCharacterNavigationRootKey(
  key: string
): key is CharacterNavigationRootKey {
  return ["character", "notes"].includes(key);
}

export function isNavigationMenuKey(key: string): key is NavigationMenuKey {
  return (
    isCharacterNavigationMenuKey(key as NavigationMenuKey) ||
    isNotesNavigationMenuKey(key as NavigationMenuKey)
  );
}

export function isCharacterNavigationMenuKey(
  key: string
): key is CharacterNavigationKey {
  return Object.keys(CHARACTER_MENUKEY_COMPONENT_MAP).includes(key);
}

export function isNotesNavigationMenuKey(
  key: string
): key is NotesNavigationKey {
  return Object.keys(NOTES_MENUKEY_COMPONENT_MAP).includes(key);
}

export default function useCharacterMenuItems({
  disable,
}: {
  disable?: NavigationMenuKey[];
}) {
  const appNavigationMenuItems: ItemType[] = useMemo(() => {
    const rootMenuItemWithChildren: (
      key: CharacterNavigationRootKey
    ) => ItemType = (key: CharacterNavigationRootKey) => {
      const rootNavigationItemBase = NAVIGATION_MENU_MAP.rootElements?.[key];

      if (!rootNavigationItemBase) {
        return null;
      }

      const _allChildren: Array<{
        key: NavigationMenuKey;
        value: NavigationElement;
      }> = [];
      const allChildren = Object.keys(
        NAVIGATION_MENU_MAP.children || {}
      ).reduce(
        (acc, navigationMenuKey) =>
          isNavigationMenuKey(navigationMenuKey)
            ? [
                ...acc,
                {
                  key: navigationMenuKey,
                  value: NAVIGATION_MENU_MAP.children[navigationMenuKey],
                },
              ]
            : acc,
        _allChildren
      );

      const allChildrenBelongingToRoot = allChildren
        .filter(({ key: childKey }) => !disable?.includes(childKey))
        .filter(({ key: childKey }) =>
          key === "character"
            ? isCharacterNavigationMenuKey(childKey)
            : key === "notes"
            ? isNotesNavigationMenuKey(childKey)
            : false
        );

      return {
        key,
        label: rootNavigationItemBase.label,
        icon: rootNavigationItemBase.icon,
        children: allChildrenBelongingToRoot.map(({ key }) => {
          return {
            key,
            label: NAVIGATION_MENU_MAP.children[key].label,
          };
        }),
      };
    };

    const rootElementKeys: CharacterNavigationRootKey[] = Object.keys(
      NAVIGATION_MENU_MAP.rootElements
    ) as CharacterNavigationRootKey[];

    return rootElementKeys.map(rootMenuItemWithChildren);
  }, [disable]);

  return appNavigationMenuItems;
}
