import React, { useState, useEffect } from "react";
import "./diceroller.css";
import { Button, Space, Typography } from "antd";

type DiceRollerProps = {
  numberOfDice: number;
  showRollCount?: boolean;
  showRollMean?: boolean;
};
const DiceRoller: React.FC<DiceRollerProps> = ({
  numberOfDice,
  showRollCount,
  showRollMean,
}) => {
  const [diceResults, setDiceResults] = useState<number[]>(
    [...Array(numberOfDice)].map(() => 0)
  );
  const [rollCount, setRollCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [rollSum, setRollSum] = useState(0);
  const [secondsSinceLastRoll, setSecondsSinceLastRoll] = useState(0);
  const [secondsSinceLastRollInterval, setSecondsSinceLastRollTimer] =
    useState<NodeJS.Timer>();

  const rollDices = () => {
    const newResults = diceResults.map(() => Math.floor(Math.random() * 6) + 1);
    setDiceResults(newResults);
    setRollCount((prevCount) => prevCount + 1);
    const _total = newResults.reduce((acc, curr) => acc + curr, 0);
    setTotal(_total);
    setRollSum((prevSum) => prevSum + _total);
    setSecondsSinceLastRoll(0);
    clearInterval(secondsSinceLastRollInterval);
    const interval = setInterval(() => {
      setSecondsSinceLastRoll((prev) => prev + 1);
    }, 1000);
    setSecondsSinceLastRollTimer(interval);
  };

  useEffect(() => {
    if (secondsSinceLastRoll > 60) {
      setSecondsSinceLastRoll(0);
      clearInterval(secondsSinceLastRollInterval);
      return;
    }
  }, [secondsSinceLastRoll, secondsSinceLastRollInterval]);

  useEffect(() => {
    setDiceResults(Array(numberOfDice).fill(0));
    setRollCount(0);
    setTotal(0);
    setRollSum(0);
  }, [numberOfDice]);

  return (
    <div>
      <Space
        direction="vertical"
        style={{ width: "100%", textAlign: "center" }}
      >
        <Button onClick={rollDices}>Würfeln</Button>
        {Boolean(secondsSinceLastRoll) && (
          <Typography.Text strong>
            Sekunden seit letztem Wurf: {secondsSinceLastRoll}
          </Typography.Text>
        )}
      </Space>
      <div
        className="dice-container"
        style={{ marginTop: 20, display: "flex", justifyContent: "center" }}
      >
        {diceResults.map((result, index) => (
          <Dice key={index} number={result} />
        ))}
      </div>
      {total > 0 && <Typography.Title level={3}>{total}</Typography.Title>}
      {!!showRollCount && (
        <Typography.Title level={4}>Würfe: {rollCount}</Typography.Title>
      )}
      {!!showRollMean && (
        <Typography.Title level={4}>
          Durchschnitt:{" "}
          {rollCount > 0 ? Math.round((rollSum / rollCount) * 100) / 100 : 0}
        </Typography.Title>
      )}
    </div>
  );
};

type DiceProps = {
  number: number;
};
const Dice: React.FC<DiceProps> = ({ number }) => (
  <div className={`dice dice-${number}`}>
    {[...Array(9)].map((_, index) => (
      <span
        key={index}
        className={`dot ${number === 1 && index === 4 ? "active" : ""} ${
          number === 2 && (index === 0 || index === 8) ? "active" : ""
        } ${
          number === 3 && (index === 0 || index === 4 || index === 8)
            ? "active"
            : ""
        } ${
          number === 4 &&
          (index === 0 || index === 2 || index === 6 || index === 8)
            ? "active"
            : ""
        } ${
          number === 5 &&
          (index === 0 ||
            index === 2 ||
            index === 4 ||
            index === 6 ||
            index === 8)
            ? "active"
            : ""
        } ${
          number === 6 &&
          (index === 0 ||
            index === 2 ||
            index === 3 ||
            index === 5 ||
            index === 6 ||
            index === 8)
            ? "active"
            : ""
        }`}
      ></span>
    ))}
  </div>
);

export default DiceRoller;
