import { AppRouting } from "../hooks/use-app-routing";
import { User } from "../hooks/use-auth-token";
import createR4SClient, { R4SClient } from "../utils/create-r4s-client";
import createContext from "./create-context";

export type AppContextValue = {
  user: User | null;
  client: R4SClient;
  setToken: (token: string | null) => void;
  routing: AppRouting;
};

export const AppContext = createContext({
  user: null,
  client: createR4SClient({ token: null }),
  setToken: (_value: string | null) => {},
} as AppContextValue);
