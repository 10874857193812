import {
  Button,
  Card,
  Col,
  Form,
  FormInstance,
  Input,
  Modal,
  notification,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import { createRef, FC, useState } from "react";
import { User } from "../hooks/use-auth-token";
import { useConditionalRedirect } from "../hooks/use-conditional-redirect";
import { useUser } from "../hooks/use-user";
import { useAppContext } from "../App";

const { useNotification } = notification;

export const AdminPage: FC = () => {
  const [, contextHolder] = useNotification();
  const { user, client } = useAppContext();
  const { useGetUserList, useCreateUser, useDeleteUser, usePatchUserPassword } =
    useUser(client);

  const { data: users } = useGetUserList();
  const { mutate: createUser } = useCreateUser();
  const { mutate: deleteUser } = useDeleteUser();
  const { mutate: changePassword } = usePatchUserPassword();

  useConditionalRedirect({
    condition: user?.role !== "ADMIN",
    navigationKey: "character-select",
  });

  const [changePasswordUserId, setChangePasswordUserId] = useState<
    number | null
  >(null);
  const [deleteUserId, setDeleteUserId] = useState<number | null>(null);

  const formRef = createRef<FormInstance>();

  return (
    <>
      {contextHolder}
      <Row>
        <Col xs={24}>
          <Card>
            <Typography.Title level={3}>Benutzer erstellen</Typography.Title>
            <Form<{ name: string; password: string; email: string }>
              name="signup"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              initialValues={{ remember: true }}
              onFinish={async ({ name, password, email }) => {
                createUser({ name, password, email });
                formRef.current?.resetFields();
              }}
              autoComplete="off"
            >
              <Form.Item
                label="Benutzername"
                labelAlign="left"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Bitte gib deinen Benutzernamen ein!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Email"
                labelAlign="left"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Bitte gib deine Email-Adresse an!",
                  },
                ]}
              >
                <Input type="email" />
              </Form.Item>

              <Form.Item
                labelAlign="left"
                label="Passwort"
                name="password"
                rules={[
                  { required: true, message: "Bitte gib dein Passwort ein!" },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 2, span: 22 }}>
                <Button type="primary" htmlType="submit">
                  Erstellen
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Card>
            <Typography.Title level={3}>Benutzer</Typography.Title>
            <Table
              dataSource={users?.map((user: any) => {
                return {
                  id: user.id,
                  name: user.profile.name,
                  email: user.email,
                  role: user.role,
                  key: user.id,
                };
              })}
              columns={[
                {
                  title: "ID",
                  dataIndex: "id",
                  key: "id",
                },
                {
                  title: "Name",
                  dataIndex: "name",
                  key: "name",
                },
                {
                  title: "Email",
                  dataIndex: "email",
                  key: "email",
                },
                {
                  title: "Rolle",
                  dataIndex: "role",
                  key: "role",
                },
                {
                  title: "Aktionen",
                  key: "actions",
                  render: (item) => {
                    return (
                      <Space>
                        <Button
                          onClick={() => setChangePasswordUserId(item.id)}
                        >
                          Passwort ändern
                        </Button>
                      </Space>
                    );
                  },
                },
              ]}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        open={!!changePasswordUserId}
        footer={null}
        onCancel={() => setChangePasswordUserId(null)}
      >
        <Typography.Title level={5}>
          Passwort von{" "}
          {
            users?.find((u: User) => u?.id === changePasswordUserId)?.profile
              .name
          }{" "}
          ändern
        </Typography.Title>
        <Form<{ password: string }>
          name="change-password"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          initialValues={{ remember: true }}
          onFinish={async ({ password }) => {
            changePassword({
              userId: changePasswordUserId!,
              newPassword: password,
            });
            setChangePasswordUserId(null);
          }}
          autoComplete="off"
        >
          <Form.Item
            labelAlign="left"
            label="Passwort"
            name="password"
            rules={[
              { required: true, message: "Bitte gib dein Passwort ein!" },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 2, span: 22 }}>
            <Button type="primary" htmlType="submit">
              Ändern
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={!!deleteUserId}
        footer={null}
        onCancel={() => setDeleteUserId(null)}
      >
        <Typography.Title level={5}>
          Benutzer{" "}
          {users?.find((u: User) => u?.id === deleteUserId)?.profile?.name}{" "}
          entfernen?
        </Typography.Title>
        <Space>
          <Button
            onClick={() => {
              deleteUser(deleteUserId!);
              setDeleteUserId(null);
            }}
          >
            Entfernen
          </Button>
          <Button onClick={() => setDeleteUserId(null)}>Abbrechen</Button>
        </Space>
      </Modal>
    </>
  );
};
