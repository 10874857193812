import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AppNavigationKey } from "./use-app-routing";
import { useAppContext } from "../App";

export const useConditionalRedirect = ({
  condition,
  navigationKey,
  excludedPaths,
  includedPaths,
}: {
  condition: boolean;
  navigationKey: AppNavigationKey;
  excludedPaths?: string[];
  includedPaths?: string[];
}) => {
  const { pathname: currentPath } = useLocation();
  const {
    routing: { appNavigationRouteMap, currentNavigationMetadata },
  } = useAppContext();

  useEffect(() => {
    if (
      currentNavigationMetadata.key !== navigationKey &&
      condition &&
      (!excludedPaths?.includes(currentPath) ||
        includedPaths?.includes(currentPath))
    ) {
      appNavigationRouteMap[navigationKey].navigate();
    }
  }, [
    condition,
    currentPath,
    excludedPaths,
    includedPaths,
    currentNavigationMetadata.key,
    appNavigationRouteMap,
    navigationKey,
  ]);
};
