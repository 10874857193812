import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  Modal,
  Progress,
  Row,
  Space,
  Tree,
  TreeSelect,
  Typography,
} from "antd";
import { useCallback, useMemo, useRef, useState } from "react";
import {
  PlusOutlined,
  MinusOutlined,
  DeleteOutlined,
  DownOutlined,
  ApiFilled,
  NodeExpandOutlined,
  NodeCollapseOutlined,
} from "@ant-design/icons";
import { DefaultOptionType } from "antd/es/cascader";
import { useCharacterRequestsContext } from ".";
import getChildNodes from "../utils/get-child-nodes";
import toRoman from "../utils/toRoman";
import DiceRoller from "../components/dice/diceroller";

export type CreateSkill = {
  name: string;
  description?: string;
  parentId: string;
};

export type SkillObject = {
  description: string;
  name: string;
  id: string;
  parentId: string | null;
};

export const CharacterSkills: React.FC = () => {
  const {
    useGetSkills,
    useIncrementSkillPoints,
    useAddSkill,
    useDeleteSkill,
    useGenerateCharacterSkillDescription,
  } = useCharacterRequestsContext();
  const { data: skillObject } = useGetSkills();
  const { mutate: incrementSkillPoints } = useIncrementSkillPoints();
  const { mutate: addSkill } = useAddSkill();
  const [selectedSkillId, setSelectedSkillId] = useState<string>(
    skillObject?.skills?.find((s) => s.parentId === null)?.id || ""
  );
  const { mutateAsync: deleteSelectedSkill } = useDeleteSkill(selectedSkillId);
  const selectedSkill = useMemo(
    () => skillObject?.skills?.find((s) => s.id === selectedSkillId),
    [skillObject?.skills, selectedSkillId]
  );

  // transform skillobjects to tree data
  const transformToTreeData = useCallback(() => {
    const skills = skillObject?.skills;
    if (!skills || !skills.find) return [];

    // get root element
    const root = skills?.find((s) => s.parentId === null);

    // if no root element exists, return empty array
    if (!root) return [];

    // return root element with children
    return [
      {
        title: root.name,
        value: root.id,
        key: root.id,
        children: getChildNodes(root, skills),
      },
    ];
  }, [skillObject]);

  const depthMap = useMemo(() => {
    const skills = skillObject?.skills;

    if (!skills || !skills.find) return new Map<string, number>();

    const getDepth = (skill: any): number => {
      if (!skill.parentId) return 0;
      const parent = skills.find((s) => s.id === skill.parentId);
      if (!parent) return 0;
      return 1 + getDepth(parent);
    };

    const map = new Map<string, number>();
    skills.forEach((s) => {
      map.set(s.id, getDepth(s));
    });

    return map;
  }, [skillObject?.skills]);

  const depthCounts = useMemo(() => {
    const depthList = skillObject?.skills?.map((s) => depthMap?.get(s.id) ?? 0);

    if (!depthList) return [0];

    // count occurences of each depth and map to array
    const occurences = Object.values(
      depthList.reduce((acc, cur) => {
        acc[cur] = (acc[cur] || 0) + 1;
        return acc;
      }, [] as { [key: number]: number })
    );

    return occurences?.length > 0 ? occurences : [0];
  }, [skillObject?.skills, depthMap]);

  const currentLevel = useMemo(() => {
    return depthCounts && depthCounts.length > 1 ? depthCounts.length - 1 : 1;
  }, [depthCounts]);

  const currentSelectedDepth = useMemo(() => {
    return depthMap?.get(selectedSkillId) || 0;
  }, [depthMap, selectedSkillId]);

  const displaySkillSelectTreeData = useMemo(() => {
    const elements = transformToTreeData();
    if (!elements || elements.length === 0) return undefined;

    return elements;
  }, [transformToTreeData]);

  const [applySkillpointsOnAdd, setApplySkillpointsOnAdd] = useState(true);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

  const toggleAllExpanded = useCallback(() => {
    if (!displaySkillSelectTreeData) return;
    const expandNodeRecursively = (node: any) => {
      if (!node) return;
      if (node.children) {
        setExpandedKeys((keys) => [...keys, node.value]);
        node.children.forEach(expandNodeRecursively);
      }
    };
    if (expandedKeys.length === 0) {
      expandNodeRecursively(displaySkillSelectTreeData[0]);
    } else {
      setExpandedKeys([]);
    }
  }, [displaySkillSelectTreeData, expandedKeys]);

  const parentSelectionTreeData = useMemo(() => {
    const getChildElements: (skills: any) => any = (
      skills: ReturnType<typeof transformToTreeData>
    ) =>
      skills?.map((t) => {
        return {
          ...t,
          value: t.value,
          label: t.title,
          depth: depthMap?.get(t.value),
          children:
            applySkillpointsOnAdd &&
            Number(depthMap?.get(t.value) || 0) + 1 >=
              Math.min(
                Number(skillObject?.skillpoints || 0),
                depthCounts[currentLevel] >= 6 ? currentLevel + 1 : currentLevel
              )
              ? undefined
              : getChildElements(
                  t.children as ReturnType<typeof transformToTreeData>
                ),
        };
      });

    const elements = getChildElements(displaySkillSelectTreeData);

    if (!elements) return undefined;
    if (elements.length === 0) return undefined;

    return elements;
  }, [
    skillObject?.skillpoints,
    depthMap,
    displaySkillSelectTreeData,
    currentLevel,
    applySkillpointsOnAdd,
    depthCounts,
  ]);

  const [isAddSkillModalVisible, setAddSkillModalVisible] = useState(false);
  const [isDeleteSkillModalVisible, setDeleteSkillModalVisible] =
    useState(false);
  const formRef = useRef<FormInstance>(null);

  const {
    mutateAsync: generateSkillDescription,
    isLoading: isGeneratingSkillDescription,
  } = useGenerateCharacterSkillDescription(
    formRef.current?.getFieldValue("name")
  );

  const skillpointsUsed = useMemo(
    () =>
      skillObject?.skills?.reduce(
        (acc, cur) => acc + (depthMap?.get(cur.id) ?? 0),
        0
      ),
    [skillObject?.skills, depthMap]
  );

  return (
    <>
      <Card bordered={false}>
        <Row>
          <Col md={24} xs={24}>
            <Row>
              <Col xs={12} style={{ padding: 5 }}>
                <Row>
                  <Col md={24} xs={24}>
                    <Typography.Title level={3}>
                      Level: {toRoman(currentLevel)}
                    </Typography.Title>
                  </Col>
                  <Col md={24} xs={24}>
                    <Progress
                      percent={Math.round(
                        (100 / 6) * depthCounts[currentLevel]
                      )}
                      steps={6}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} style={{ padding: 5 }}>
                <Row>
                  <Col xs={24}>
                    <Typography.Title level={3}>
                      Skillpoints: {skillObject?.skillpoints || "-"}
                    </Typography.Title>
                  </Col>
                  <Col xs={24}>
                    <Button.Group size="middle">
                      <Button
                        onClick={() => incrementSkillPoints(1)}
                        type="dashed"
                      >
                        <PlusOutlined />
                      </Button>
                      <Button
                        disabled={
                          skillObject?.skillpoints === undefined ||
                          skillObject.skillpoints === 0
                        }
                        onClick={() => incrementSkillPoints(-1)}
                        type="dashed"
                      >
                        <MinusOutlined />
                      </Button>
                    </Button.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col md={12} xs={24} style={{ padding: 5 }}>
            <Row>
              <Typography.Title level={4}>
                Skillbaum ({skillpointsUsed} Skillpunkte eingesetzt)
              </Typography.Title>
            </Row>
            <Row>
              <Col md={24} xs={24}>
                <Space size={5} direction="horizontal">
                  <Space size={5}>
                    <Button type="dashed" onClick={toggleAllExpanded}>
                      {expandedKeys.length === 0 ? (
                        <NodeExpandOutlined />
                      ) : (
                        <NodeCollapseOutlined />
                      )}
                    </Button>
                    <Button
                      type="primary"
                      icon={<DeleteOutlined />}
                      disabled={
                        selectedSkillId === undefined ||
                        selectedSkillId === "" ||
                        selectedSkill === undefined ||
                        selectedSkill?.parentId === null ||
                        skillObject?.skills?.find(
                          (s) => s.parentId === selectedSkillId
                        ) !== undefined
                      }
                      danger
                      onClick={() => {
                        setDeleteSkillModalVisible(true);
                      }}
                    />
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        setAddSkillModalVisible(true);
                      }}
                    />
                  </Space>
                </Space>
              </Col>
              <Col md={24} xs={24} style={{ paddingTop: 15 }}>
                <Tree
                  showLine
                  expandAction="doubleClick"
                  switcherIcon={<DownOutlined />}
                  activeKey={selectedSkillId}
                  onSelect={(selectedKeys) => {
                    setSelectedSkillId(
                      skillObject?.skills?.find((s) => s.id === selectedKeys[0])
                        ?.id || ""
                    );
                  }}
                  selectedKeys={[selectedSkillId]}
                  onExpand={(expandedKeys) => {
                    setExpandedKeys(expandedKeys as string[]);
                  }}
                  expandedKeys={expandedKeys}
                  treeData={displaySkillSelectTreeData}
                />
              </Col>
            </Row>
          </Col>
          {/* {selectedSkill && <Divider />} */}
          <Col md={12} xs={24} style={{ padding: 5 }}>
            <Row>
              <Col md={24} xs={24}>
                <Typography.Paragraph>
                  {selectedSkill?.description || ""}
                </Typography.Paragraph>
              </Col>
              {selectedSkillId && (
                <Col xs={24} style={{ padding: 5 }}>
                  <Card style={{ margin: 2 }} title="Würfel">
                    {currentSelectedDepth > 0 ? (
                      <DiceRoller numberOfDice={currentSelectedDepth + 1} />
                    ) : (
                      <DiceRoller numberOfDice={1} />
                    )}
                  </Card>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Card>

      <Modal
        title="Skill löschen"
        open={isDeleteSkillModalVisible}
        onOk={async () => {
          await deleteSelectedSkill();
          setDeleteSkillModalVisible(false);
        }}
        onCancel={() => {
          setDeleteSkillModalVisible(false);
        }}
      >
        <Typography.Paragraph>
          Möchtest du den Skill wirklich löschen?
        </Typography.Paragraph>
      </Modal>

      <Modal
        title="Skill hinzufügen"
        open={isAddSkillModalVisible}
        onOk={() => {
          formRef?.current?.submit();
        }}
        onCancel={() => {
          setAddSkillModalVisible(false);
        }}
        width={800}
      >
        <Form<CreateSkill>
          ref={formRef}
          onFinish={(values) => {
            addSkill({
              name: values.name,
              description:
                values.description && values.description !== ""
                  ? values.description
                  : undefined,
              parentId: values.parentId,
            });
            const skillPointsNeeded =
              (depthMap.get(values.parentId ?? "") ?? -1) + 1;

            if (applySkillpointsOnAdd && skillPointsNeeded > 0) {
              incrementSkillPoints(skillPointsNeeded * -1);
            }
            setAddSkillModalVisible(false);
          }}
        >
          <Form.Item label="Parent">
            <Form.Item name="parentId" noStyle>
              <SkillInput treeData={parentSelectionTreeData || []} />
            </Form.Item>
          </Form.Item>
          <Form.Item label="Name">
            <Row>
              <Col md={12} xs={24}>
                <Form.Item name="name" noStyle required>
                  <Input />
                </Form.Item>
              </Col>

              <Col md={12} xs={24}>
                <Button
                  type="primary"
                  style={{ margin: 5 }}
                  onClick={async () => {
                    const description = await generateSkillDescription(
                      formRef.current?.getFieldValue("name")
                    );
                    formRef.current?.setFieldsValue({ description });
                  }}
                  disabled={isGeneratingSkillDescription}
                >
                  <ApiFilled /> Beschreibung generieren
                </Button>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item label="Beschreibung">
            <Form.Item name="description" noStyle>
              <Input.TextArea />
            </Form.Item>
          </Form.Item>
          <Checkbox
            value={applySkillpointsOnAdd}
            onChange={(e) => setApplySkillpointsOnAdd(e.target.checked)}
          >
            Skillpunkte automatisch abziehen
          </Checkbox>
        </Form>
      </Modal>
    </>
  );
};

interface SkillInputProps {
  treeData: any[];
  value?: {
    name: string;
    description?: string;
    parentId: string;
    id: string;
  };
  onChange?: (parentId: string) => void;
}

const SkillInput: React.FC<
  SkillInputProps & {
    treeData: DefaultOptionType[];
  }
> = ({ value, onChange, treeData }) => {
  return (
    <TreeSelect
      showSearch
      style={{ width: "100%" }}
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      placeholder="Parent auswählen"
      allowClear
      onChange={(value) => {
        onChange?.(value);
      }}
      value={value?.id}
      treeDefaultExpandAll
      treeData={treeData}
    />
  );
};
