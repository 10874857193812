import { useMemo } from "react";
import { useMutation, UseMutationOptions, useQuery } from "react-query";
import { CreateCharacterArgs } from "../components/create-character";
import { R4SClient } from "../utils/create-r4s-client";

export const useUser = (client: R4SClient) => {
  return useMemo(
    () => ({
      useGetCharacters: () =>
        useQuery(
          "characters",
          async () => {
            return await client?.getCharacters();
          },
          {
            enabled: client.isAuthenticated,
          }
        ),
      useGetProfile: () =>
        useQuery("profile", async () => await client?.getProfiles()),
      useCreateCharacter: (
        options: Omit<
          UseMutationOptions<any, unknown, CreateCharacterArgs, unknown>,
          "mutationKey" | "mutationFn"
        >
      ) =>
        useMutation(
          ["characters", "create"],
          async (args: CreateCharacterArgs) =>
            await client?.createCharacter(args),
          options
        ),
      useCreateUser: () =>
        useMutation(
          ["user", "create"],
          async (args: { name: string; password: string; email: string }) =>
            await client?.createUser(args.name, args.password, args.email)
        ),
      useGetUserList: () =>
        useQuery("user", async () => await client?.getUserList()),
      useDeleteUser: () =>
        useMutation(
          ["user", "delete"],
          async (userId: number) => await client?.deleteUser(userId)
        ),
      usePatchUserPassword: () =>
        useMutation(
          ["user", "patch"],
          async (args: { userId: number; newPassword: string }) =>
            await client?.patchUserPassword(args.userId, args.newPassword)
        ),
    }),
    [client]
  );
};
