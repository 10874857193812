import { Card, Col, Row, Typography } from "antd";
import React from "react";
import { motion } from "framer-motion";
import { useAppContext } from "../App";

export const ProfilePage: React.FC<{
  characters: { name: string; id: string }[];
}> = ({ characters }) => {
  const {
    routing: { characterSheetNavigationRouteMap },
  } = useAppContext();

  const handleCharacterSelect = async (id: string) => {
    await new Promise((resolve) => setTimeout(resolve, 200));
    characterSheetNavigationRouteMap[id]["character-sheet"].navigate();
  };

  return (
    <Row
      style={{
        overflowY: "auto",
        overflowX: "hidden",
        padding: "5px 10px",
      }}
    >
      {Object.entries(characterSheetNavigationRouteMap).map(([id, props]) => {
        return (
          <Col style={{ padding: 4 }} key={id} xs={24} md={12} lg={8} xl={6}>
            <motion.div
              whileHover={{
                scale: 1.015,
                boxShadow: "0px 0px 8px rgba(0,0,0,0.2)",
              }}
              whileTap={{ scale: 0.98 }}
              onClick={() => handleCharacterSelect(id)}
              style={{ cursor: "pointer" }}
            >
              <Card
                bordered={false}
                style={{
                  minHeight: 180,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography.Title level={2}>
                  {props["character-sheet"].label}
                </Typography.Title>
              </Card>
            </motion.div>
          </Col>
        );
      })}
    </Row>
  );
};
