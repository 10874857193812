import { NavigationMenuKey } from "../hooks/use-app-routing";

export class PathnameMenukeyMatcher {
  private static expressionMenukeyMap: Map<RegExp | string, NavigationMenuKey> =
    new Map<RegExp | string, NavigationMenuKey>([
      [/admin/, "admin"],
      [/character\/create/, "character-create"],
      [/character-infos/, "character-infos"],
      [/character-dice/, "character-dice"],
      [/character-fight/, "character-fight"],
      [/character-money/, "character-money"],
      [/character-inventory/, "character-inventory"],
      [/character-skills/, "character-skills"],
      [/character-armor/, "character-armor"],
      [/character-weapons/, "character-weapons"],
      [/character-spells/, "character-spells"],
      [/character-injuries/, "character-injuries"],
      [/notes-list/, "notes-list"],
      [/notes-map/, "notes-map"],
      [/notes-quests/, "notes-quests"],
      [/notes-acquaintances/, "notes-acquaintances"],
      [/character\//, "character-sheet"],
      [/character/, "character-select"],
    ]);

  public static getMenuKey(pathname: string): NavigationMenuKey {
    for (const [expression, menukey] of Array.from(this.expressionMenukeyMap)) {
      if (pathname.match(expression)) {
        return menukey;
      }
    }
    return "login";
  }

  public static getCharacterId(pathname: string): string | null {
    const segments = pathname.split("/");
    const characterSegmentIndex = segments.indexOf("character");

    if (characterSegmentIndex > -1) {
      return segments[characterSegmentIndex + 1];
    }

    return null;
  }
}
