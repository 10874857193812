import "./App.css";
import { AppContent } from "./pages/app-content";
import { useQueryClient } from "react-query";
import { AppContext } from "./context-factory/auth-context";
import createR4SClient from "./utils/create-r4s-client";
import { useEffect, useMemo, useState } from "react";
import { Button, ConfigProvider, theme } from "antd";
import useAuth from "./hooks/use-auth-token";
import { BulbFilled, BulbOutlined } from "@ant-design/icons";
import { AppRouting, useAppRouting } from "./hooks/use-app-routing";
import { useUser } from "./hooks/use-user";

export const { Provider: AppContextProvider, useValue: useAppContext } =
  AppContext;

function App() {
  const { setToken, token, user } = useAuth();

  const client = useMemo(() => {
    return createR4SClient({
      token,
      baseURL: process.env.REACT_APP_API_URL || "https://r4s.dev-marc.com/api",
    });
  }, [token]);

  const { useGetCharacters } = useUser(client);
  const { data: characters } = useGetCharacters();

  const queryClient = useQueryClient();

  const routing: AppRouting = useAppRouting(characters);
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    if (!client.isAuthenticated) {
      routing.appNavigationRouteMap.login.navigate();
    }
    queryClient.invalidateQueries("characters");
  }, [routing.appNavigationRouteMap, client, queryClient]);

  return (
    <div className="App">
      <ConfigProvider
        theme={{
          // experiment with dark mode
          algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
        }}
      >
        <AppContextProvider value={{ client, user, setToken, routing }}>
          <Button
            style={{
              position: "fixed",
              top: 16,
              right: 60,
              zIndex: 990000,
              width: 140,
              fontSize: 14,
            }}
            onClick={() => setIsDarkMode(!isDarkMode)}
          >
            {isDarkMode ? <BulbFilled /> : <BulbOutlined />}{" "}
            {isDarkMode ? "Light Mode" : "Dark Mode"}
          </Button>
          <AppContent />
        </AppContextProvider>
      </ConfigProvider>
    </div>
  );
}

export default App;
