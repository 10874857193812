import { Button, Card, Col, Row, Space, Table, Tag, Typography } from "antd";
import { useCharacterRequestsContext } from ".";
import { PlusOutlined } from "@ant-design/icons";
import { useMemo, useState } from "react";
import AddArmorItemModal from "../components/armor-add-item-modal";
import SecurityQuestionModal from "../components/security-query-modal";

export const CharacterArmor: React.FC<{}> = () => {
  const { useGetArmor, useDeleteItemFromArmor } = useCharacterRequestsContext();
  const { data: armor } = useGetArmor();
  const { mutateAsync: deleteItemFromArmor } = useDeleteItemFromArmor();
  const [isAddItemModalVisible, setAddItemModalVisible] = useState(false);
  const [deleteModalItem, setDeleteModalItem] = useState<{
    name: string;
    id: string;
  } | null>(null);
  const isMagicalArmor = useMemo(
    () =>
      armor?.armorItems.reduce((acc: boolean, item: { isMagical: boolean }) => {
        return acc || item.isMagical;
      }, false),
    [armor]
  );

  return (
    <div>
      <Row>
        <Col
          md={24}
          xs={24}
          style={{
            alignItems: "center",
            marginBottom: "8px",
          }}
        >
          <Card
            bordered={false}
            style={{
              maxHeight: "28rem",
            }}
          >
            <Row>
              <Col md={12} xs={24}>
                <Space>
                  <Typography.Title level={3}>Rüstungswert: </Typography.Title>
                  <Typography.Title level={3} style={{ color: "green" }}>
                    {armor?.armorItems.reduce(
                      (acc: number, item: { armorPoints: number }) => {
                        return acc + item.armorPoints;
                      },
                      0
                    ) || 0}
                  </Typography.Title>
                </Space>
              </Col>
              <Col md={12} xs={24}>
                <Space>
                  <Typography.Title level={3}>Kann zaubern: </Typography.Title>
                  <Typography.Title
                    level={3}
                    style={{
                      color: isMagicalArmor ? "green" : "red",
                    }}
                  >
                    {isMagicalArmor ? "Ja" : "Nein"}
                  </Typography.Title>
                </Space>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col md={24} xs={24}>
          <Card
            title={
              <Row>
                <Space>
                  <Typography.Title level={4}>
                    Rüstungsgegenstände
                  </Typography.Title>
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setAddItemModalVisible(true);
                    }}
                  />
                </Space>
              </Row>
            }
            style={{
              overflowX: "auto",
              height: "calc(100vh - 362px)",
            }}
            bodyStyle={{ padding: 0 }}
            bordered={false}
          >
            <Table
              style={{ minHeight: "100%" }}
              dataSource={armor?.armorItems || []}
              // scroll={{ x: "max-content" }}
              columns={[
                {
                  title: "Name",
                  dataIndex: "name",
                  key: "name",
                },
                {
                  title: "Rüstungswert",
                  dataIndex: "armorPoints",
                  key: "armorPoints",
                  ellipsis: true,
                },
                {
                  title: "Kann zaubern",
                  dataIndex: "isMagical",
                  key: "isMagical",
                  render(value, record, index) {
                    return value ? "Ja" : "Nein";
                  },
                  ellipsis: true,
                },
                {
                  title: "Typ",
                  dataIndex: "armorPart",
                  key: "armorPart",
                  ellipsis: true,
                  responsive: ["md"],
                  render(value, record, index) {
                    switch (value) {
                      case "HEAD":
                        return <Tag color="red">Kopf</Tag>;
                      case "BODY":
                        return <Tag color="magenta">Körper</Tag>;
                      case "LEGS":
                        return <Tag color="cyan">Beine</Tag>;
                      case "ARMS":
                        return <Tag color="green">Arme</Tag>;
                      case "HANDS":
                        return <Tag color="gold">Hände</Tag>;
                      case "SPECIAL":
                        return <Tag color="blue">Spezial</Tag>;
                      default:
                        return <Tag color="error">Unbekannt</Tag>;
                    }
                  },
                },
                {
                  title: "Aktionen",
                  dataIndex: "actions",
                  key: "actions",
                  ellipsis: true,
                  render(value, record, index) {
                    return (
                      <Space size={5}>
                        <Button
                          type="primary"
                          shape="circle"
                          size="small"
                          danger
                          onClick={() => {
                            setDeleteModalItem({
                              name: record.name,
                              id: record.id,
                            });
                          }}
                        >
                          X
                        </Button>
                      </Space>
                    );
                  },
                },
              ]}
            />
          </Card>
        </Col>
      </Row>
      <AddArmorItemModal
        open={isAddItemModalVisible}
        onCancel={() => setAddItemModalVisible(false)}
        afterFinish={() => setAddItemModalVisible(false)}
      />
      <SecurityQuestionModal
        open={deleteModalItem !== null}
        onCancel={() => setDeleteModalItem(null)}
        onConfirm={async () => {
          setDeleteModalItem(null);

          if (deleteModalItem !== null) {
            await deleteItemFromArmor(deleteModalItem.id);
          }
        }}
        title="Rüstungsgegenstand löschen"
        prompt={`Möchtest du den Rüstungsgegenstand "${deleteModalItem?.name}" wirklich löschen?`}
        confirmText="Löschen"
        cancelText="Abbrechen"
      />
    </div>
  );
};
