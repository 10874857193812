import { Modal } from "antd";

const SecurityQuestionModal: React.FC<{
  title: string;
  prompt: string;
  confirmText: string;
  cancelText: string;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}> = ({ prompt, onConfirm, onCancel, title, open }) => {
  return (
    <Modal
      title={title}
      visible={open}
      onOk={() => {
        onConfirm();
      }}
      onCancel={() => {
        onCancel();
      }}
    >
      <p>{prompt}</p>
    </Modal>
  );
};

export default SecurityQuestionModal;
