import { useEffect, useState } from "react";
import DiceRoller from "./dice/diceroller";
import { Input } from "antd";

export const DiceCountInput = ({
  onCountChange,
}: {
  onCountChange: (count: number) => void;
}) => {
  const [count, setCount] = useState<number | null>(1);

  useEffect(() => {
    if (count === null) return;
    onCountChange(count);
  }, [count, onCountChange]);

  return (
    <Input
      type="number"
      style={{ width: 200 }}
      value={count || undefined}
      onChange={(e) => {
        const nextCount = parseInt(e.target.value);
        if (Number.isNaN(nextCount)) {
          setCount(null);
          return;
        }
        setCount(nextCount);
      }}
    />
  );
};

export const DiceDisplay = (args?: {
  cooldown?: number;
  cooldownDestroyCooldown?: number;
  count?: number;
}) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <DiceRoller numberOfDice={args?.count || 1} />
    </div>
  );
};
