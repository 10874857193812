import { Button, Col, Row, Typography, Card, Space } from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  UndoOutlined,
  WalletOutlined,
  WalletFilled,
} from "@ant-design/icons";

import React, { useMemo, useState } from "react";
import { MonetaryAmount } from "../hooks/use-r4s-client";
import { MoneyInput } from "../components/money-input";
import { MoneyBagde } from "../components/money-badge";
import money, { Money } from "../utils/money";
import { useCharacterRequestsContext } from ".";

const { Title } = Typography;

export const CharacterMoney: React.FC = () => {
  const [moneyDiff, setMoney] = useState<Money>({ copper: 0 });
  const { useGetMoney, usePatchMoney } = useCharacterRequestsContext();
  const { data: currentMoney } = useGetMoney();
  const functionalMoney = useMemo(() => money(moneyDiff), [moneyDiff]);

  const { mutateAsync: addMoney } = usePatchMoney(functionalMoney.toObject());
  const { mutateAsync: removeMoney } = usePatchMoney(
    functionalMoney.invert().toObject()
  );

  return (
    <>
      <Row>
        <Col xs={24} md={12}>
          <Card
            bordered={true}
            style={{ height: "100%" }}
            title={
              <Title level={4}>
                <WalletOutlined />
                {` `} Geldbörse
              </Title>
            }
          >
            <Row>
              {currentMoney &&
                [
                  { label: "Gold", value: currentMoney.gold, currency: "gold" },
                  {
                    label: "Silber",
                    value: currentMoney.silver,
                    currency: "silver",
                  },
                  {
                    label: "Kupfer",
                    value: currentMoney.copper,
                    currency: "copper",
                  },
                ].map((item) => (
                  <Col xs={8} style={{ padding: 25 }}>
                    <MoneyBagde
                      coinCurrency={item.currency as keyof MonetaryAmount}
                      count={item.value}
                      overflowCount={1_000_000}
                    />
                  </Col>
                ))}
            </Row>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card
            bordered={true}
            style={{ height: "100%" }}
            title={
              <Title level={4}>
                <ArrowLeftOutlined />
                <WalletFilled />
                {` `} Transaktion
              </Title>
            }
          >
            <Row>
              <Col xs={24} md={8} style={{ padding: 4 }}>
                <MoneyInput
                  currency="gold"
                  value={functionalMoney.toObject()}
                  setValue={setMoney}
                  moneyInputButtonTypes={[-10, -1, 1, 10]}
                  disableNegative
                />
              </Col>
              <Col xs={24} md={8} style={{ padding: 4 }}>
                <MoneyInput
                  currency="silver"
                  value={functionalMoney.toObject()}
                  setValue={setMoney}
                  moneyInputButtonTypes={[-1, 1]}
                  disableNegative
                />
              </Col>
              <Col xs={24} md={8} style={{ padding: 4 }}>
                <MoneyInput
                  currency="copper"
                  value={functionalMoney.toObject()}
                  setValue={setMoney}
                  moneyInputButtonTypes={[-1, 1]}
                  disableNegative
                />
              </Col>

              <Col xs={24} style={{ padding: 4, paddingTop: 12 }}>
                <Space>
                  <Button.Group>
                    <Button
                      size="large"
                      type="primary"
                      disabled={functionalMoney.toCopper() <= 0}
                      onClick={() => removeMoney()}
                    >
                      <>
                        <ArrowLeftOutlined />
                        <WalletOutlined /> ausgeben
                      </>
                    </Button>
                    <Button
                      type="primary"
                      size="large"
                      disabled={functionalMoney.toCopper() <= 0}
                      onClick={() => addMoney()}
                    >
                      <>
                        <ArrowRightOutlined />
                        <WalletFilled /> einnehmen
                      </>
                    </Button>
                  </Button.Group>

                  <Button
                    onClick={() => setMoney({ gold: 0, silver: 0, copper: 0 })}
                    type={"default"}
                    size="large"
                  >
                    <UndoOutlined />
                  </Button>
                </Space>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
