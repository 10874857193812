import { Button, Card, Col, List, Row, Space, Tooltip, Typography } from "antd";
import {
  PlusOutlined,
  MinusOutlined,
  UndoOutlined,
  DeleteOutlined,
  InfoCircleFilled as InfoIcon,
  LoadingOutlined,
} from "@ant-design/icons";
import { useCharacterRequestsContext } from ".";
import { MagicStone } from "../utils/create-r4s-client";
import AddMagicStoneModal from "../components/spells-add-magicstone";
import { useState } from "react";

export const CharacterSpells: React.FC<{}> = () => {
  const {
    useGetMagicStones,
    usePatchMagicStoneSpellslotsUsed,
    usePatchCharacterDivineSpellsUsed,
    useDeleteMagicStone,
    useGetInfo,
  } = useCharacterRequestsContext();
  const { data: magicStoneList } = useGetMagicStones();
  const { data: characterInfo } = useGetInfo();
  const {
    mutate: patchMagicStoneSpellslotsUsed,
    isLoading: isPatchingMagicStoneSpellslots,
  } = usePatchMagicStoneSpellslotsUsed();
  const { mutate: patchCharacterDivineSpellsUsed } =
    usePatchCharacterDivineSpellsUsed();
  const { mutate: deleteMagicStone } = useDeleteMagicStone();

  const magicStoneSizeFactorMap: { [key in MagicStone["size"]]: number } = {
    SMALL: 1,
    MEDIUM: 2,
    LARGE: 3,
  };

  const magicStoneTypeFactorMap: { [key in MagicStone["type"]]: number } = {
    TURBID: 1,
    CLEAR: 2,
  };

  const [addMagicStoneModalOpen, setAddMagicStoneModalOpen] = useState(false);

  return (
    <>
      <Card>
        <Row>
          <Col xs={24} md={8} style={{ paddingBottom: 20 }}>
            <Space direction="horizontal" align="baseline">
              <Tooltip
                arrow={true}
                title={`Göttliche Spells sind Zaubersprüche, die mithilfe von göttlicher
                        Magie gewirkt werden. Sie können nur 5x pro Tag gewirkt werden.`}
              >
                <InfoIcon />
              </Tooltip>
              <Typography.Title level={4}>Göttliche Spells</Typography.Title>
            </Space>
            <Typography.Paragraph strong>
              Verbraucht: {characterInfo?.divineSpellsUsed || 0}
              <br />
              Verbleibend: {5 - (characterInfo?.divineSpellsUsed || 0)}
            </Typography.Paragraph>
            <Button.Group>
              <Button
                type="primary"
                onClick={() => patchCharacterDivineSpellsUsed({ diff: 1 })}
                disabled={
                  characterInfo?.divineSpellsUsed === undefined ||
                  characterInfo?.divineSpellsUsed >= 5
                }
              >
                <MinusOutlined />
              </Button>
              <Button
                type="primary"
                onClick={() => patchCharacterDivineSpellsUsed({ diff: -1 })}
                disabled={
                  characterInfo?.divineSpellsUsed === undefined ||
                  characterInfo?.divineSpellsUsed <= 0
                }
              >
                <PlusOutlined />
              </Button>
              <Button
                type="primary"
                onClick={() =>
                  patchCharacterDivineSpellsUsed({
                    diff: 0 - (characterInfo?.divineSpellsUsed || 0),
                  })
                }
                disabled={
                  characterInfo?.divineSpellsUsed === undefined ||
                  characterInfo?.divineSpellsUsed <= 0
                }
              >
                <UndoOutlined />
              </Button>
            </Button.Group>
          </Col>
          <Col xs={24} md={16}>
            <List
              grid={{
                xs: 1,
                sm: 1,
                md: 1,
                lg: 2,
                xl: 2,
                xxl: 2,
              }}
              style={{ marginTop: 15 }}
              header={
                <>
                  <Space
                    direction="horizontal"
                    align="baseline"
                    style={{ paddingBottom: 10 }}
                  >
                    <Tooltip
                      arrow={true}
                      title={`Magiesteine sind magische Steine, die von Magiern verwendet
                      werden, um ihre Zaubersprüche zu wirken. Sie sind in drei
                      Größen erhältlich: klein, mittel und groß. Kleinere Steine
                      haben weniger Spellslots. Die Qualität eines Magiesteins
                      wird durch die Transparenz bestimmt. Trübe Steine haben
                      weniger Spellslots als klare Steine. Die Anzahl der
                      Spellslots eines Magiesteins ist abhängig von der Größe und
                      der Qualität des Steins.`}
                    >
                      <InfoIcon />
                    </Tooltip>
                    <Typography.Title level={4}>
                      Magiesteine und Spellslots
                    </Typography.Title>
                  </Space>
                </>
              }
              footer={
                <Button
                  type="primary"
                  onClick={() => setAddMagicStoneModalOpen(true)}
                >
                  <PlusOutlined />
                  Magiestein hinzufügen
                </Button>
              }
              dataSource={magicStoneList || []}
              renderItem={(item: MagicStone) => {
                const maxSpellSlots =
                  magicStoneSizeFactorMap[item?.size || "SMALL"] *
                  magicStoneTypeFactorMap[item?.type || "TURBID"] *
                  20;
                const spellslotsAvailable =
                  maxSpellSlots - (item.spellSlotsUsed || 0);

                return (
                  <List.Item key={item.id}>
                    <Card
                      bordered={false}
                      style={{
                        opacity: 0.8,
                        marginTop: 5,
                      }}
                      title={
                        <>
                          <Typography.Title level={4}>{`${
                            item?.size === "SMALL"
                              ? "Kleiner"
                              : item?.size === "MEDIUM"
                              ? "Mittlerer"
                              : "Großer"
                          } ${
                            item.type === "TURBID" ? "trüber" : "klarer"
                          } Magiestein`}</Typography.Title>
                          {isPatchingMagicStoneSpellslots ? (
                            <LoadingOutlined style={{ fontSize: 24 }} />
                          ) : (
                            <Typography.Title level={4}>{`${
                              maxSpellSlots - (item.spellSlotsUsed || 0)
                            }/${maxSpellSlots}`}</Typography.Title>
                          )}
                        </>
                      }
                    >
                      <Button.Group>
                        {Array.from({
                          length:
                            magicStoneSizeFactorMap[item?.size || "SMALL"] + 1,
                        }).map((_, i) => (
                          <Button
                            size="middle"
                            onClick={() =>
                              patchMagicStoneSpellslotsUsed({
                                magicStoneId: item.id,
                                diff: i + 1,
                              })
                            }
                            disabled={
                              spellslotsAvailable < i + 1 ||
                              isPatchingMagicStoneSpellslots
                            }
                            type="primary"
                          >
                            {i + 1}
                          </Button>
                        ))}
                        <Button
                          type="primary"
                          size="middle"
                          danger
                          disabled={
                            item.spellSlotsUsed === 0 ||
                            isPatchingMagicStoneSpellslots
                          }
                          onClick={() => {
                            patchMagicStoneSpellslotsUsed({
                              magicStoneId: item.id,
                              diff: 0 - item.spellSlotsUsed,
                            });
                          }}
                        >
                          <UndoOutlined />
                        </Button>
                        <Button
                          type="primary"
                          size="middle"
                          danger
                          onClick={() => {
                            deleteMagicStone(item.id);
                          }}
                        >
                          <DeleteOutlined />
                        </Button>
                      </Button.Group>
                    </Card>
                  </List.Item>
                );
              }}
            />
          </Col>
        </Row>
        <AddMagicStoneModal
          open={addMagicStoneModalOpen}
          onCancel={() => setAddMagicStoneModalOpen(false)}
          afterFinish={() => setAddMagicStoneModalOpen(false)}
        />
      </Card>
    </>
  );
};
