import { Checkbox, Form, FormInstance, Input, Modal } from "antd";
import { FC, useRef, useState } from "react";
import { useCharacterRequestsContext } from "../character";

type MutateItemModalProps =
  | {
      isOpen: boolean;
      permanentInjury:
        | { id: string; description: string; isIncurable: true }
        | {
            id: string;
            description: string;
            daysUntilHealed: number;
            isIncurable: false;
          }
        | undefined;
      onCancel: CallableFunction;
      afterFinish: CallableFunction;
      initalValues?: { name: string; description: string };
      mode: "add";
    }
  | {
      isOpen: boolean;
      permanentInjury: { id: string; name: string } | undefined;
      onCancel: CallableFunction;
      afterFinish: CallableFunction;
      initalValues: { name: string; description: string };
      mode: "edit";
      itemId: string;
    };

const isUpdateProps = (
  props: MutateItemModalProps
): props is MutateItemModalProps & {
  initalValues: { name: string; description: string };
  mode: "edit";
  itemId: string;
} => {
  return props.mode === "edit";
};

const MutatePermanentInjuryModal: FC<MutateItemModalProps> = (props) => {
  const { onCancel, afterFinish, initalValues, mode, isOpen } = props;
  const { useAddPermanentInjury, usePatchPermanentInjury } =
    useCharacterRequestsContext();
  const { mutate: addPermanentInjury } = useAddPermanentInjury();
  const { mutate: updatePermanentInjury } = usePatchPermanentInjury();
  const formRef = useRef<FormInstance>(null);
  const [isIncurable, setIsIncurable] = useState<boolean>(true);

  return (
    <Modal
      open={isOpen}
      onCancel={() => {
        onCancel();
        formRef.current?.resetFields();
      }}
      title={mode === "add" ? `Verletzung hinzufügen` : `Verletzung bearbeiten`}
      onOk={() => {
        formRef.current?.submit();
      }}
    >
      {
        <Form<{
          description: string;
          daysUntilHealed: number;
        }>
          ref={formRef}
          onFinish={(payload) => {
            if (isUpdateProps(props)) {
              updatePermanentInjury({ injuryId: props.itemId, args: payload });
            } else {
              addPermanentInjury({
                ...payload,
                daysUntilHealed:
                  isIncurable === false
                    ? Number(payload.daysUntilHealed)
                    : undefined,
                isIncurable,
              });
            }
            afterFinish();
          }}
          initialValues={initalValues}
        >
          <Form.Item label="Beschreibung">
            <Form.Item name="description" noStyle>
              <Input.TextArea />
            </Form.Item>
          </Form.Item>
          <Form.Item label="Unheilbar">
            <Form.Item name="isIncurable" noStyle>
              <Checkbox
                onChange={(e) =>
                  setIsIncurable(
                    e.target.checked === undefined ? true : e.target.checked
                  )
                }
                checked={isIncurable}
              />
            </Form.Item>
          </Form.Item>
          {isIncurable === false && (
            <Form.Item label="Heilungsdauer (Tage)">
              <Form.Item name="daysUntilHealed" noStyle>
                <Input type="number" />
              </Form.Item>
            </Form.Item>
          )}
        </Form>
      }
    </Modal>
  );
};

export default MutatePermanentInjuryModal;
