import React from "react";
import { Table, Button, Dropdown, Modal } from "antd";
import { MoreOutlined } from "@ant-design/icons";

type ItemAction = "edit" | "delete" | "showDetails";

const InventoryContentTable: React.FC<{
  data: Array<{
    id: string;
    name: string;
    description: string;
  }>;
  onDeleted: (id: string) => void;
  onEdit: (id: string) => void;
}> = ({ data, onDeleted, onEdit }) => {
  const [itemForDetails, setItemForDetails] = React.useState<any>(null);

  return (
    <>
      <Table dataSource={data} scroll={{ x: "max-content" }}>
        <Table.Column title="Name" dataIndex="name" />
        <Table.Column
          title="Aktionen"
          render={(item) => (
            <Dropdown
              menu={{
                items: (["edit", "delete", "showDetails"] as ItemAction[]).map(
                  (action) => {
                    const actionString = {
                      edit: "Bearbeiten",
                      delete: "Löschen",
                      showDetails: "Details anzeigen",
                    }[action];

                    const actionOnClick = {
                      edit: () => onEdit(item.id),
                      delete: () => onDeleted(item.id),
                      showDetails: () => setItemForDetails(item),
                    }[action];

                    return {
                      key: action,
                      label: actionString,
                      title: actionString,
                      onClick: actionOnClick,
                    };
                  }
                ),
              }}
              trigger={["click"]}
            >
              <Button>
                <MoreOutlined />
              </Button>
            </Dropdown>
          )}
        />
      </Table>
      <Modal
        title="Details"
        open={!!itemForDetails}
        closable={true}
        onCancel={() => setItemForDetails(null)}
        footer={null}
      >
        <p>{itemForDetails?.name}</p>
        <p>{itemForDetails?.description}</p>
      </Modal>
    </>
  );
};

export default InventoryContentTable;
