import { Form, FormInstance, Input, Modal } from "antd";
import { FC, useRef } from "react";
import { useCharacterRequestsContext } from "../../character";

type MutateItemModalProps =
  | {
      inventoryContainer: { id: string; name: string } | undefined;
      onCancel: CallableFunction;
      afterFinish: CallableFunction;
      initalValues?: { name: string; description: string };
      mode: "add";
    }
  | {
      inventoryContainer: { id: string; name: string } | undefined;
      onCancel: CallableFunction;
      afterFinish: CallableFunction;
      initalValues: { name: string; description: string };
      mode: "edit";
      itemId: string;
    };

const isUpdateProps = (
  props: MutateItemModalProps
): props is MutateItemModalProps & {
  initalValues: { name: string; description: string };
  mode: "edit";
  itemId: string;
} => {
  return props.mode === "edit";
};

const MutateItemModal: FC<MutateItemModalProps> = (props) => {
  const { inventoryContainer, onCancel, afterFinish, initalValues, mode } =
    props;
  const { useAddItem, useUpdateItem } = useCharacterRequestsContext();
  const { mutate: addItem } = useAddItem(inventoryContainer?.id);
  const { mutate: updateItem } = useUpdateItem(inventoryContainer?.id);
  const formRef = useRef<FormInstance>(null);

  return (
    <Modal
      open={true}
      onCancel={() => {
        onCancel();
        formRef.current?.resetFields();
      }}
      title={
        mode === "add"
          ? `Item zu ${inventoryContainer?.name} hinzufügen`
          : `Item ${initalValues?.name} bearbeiten`
      }
      onOk={() => {
        formRef.current?.submit();
      }}
    >
      {
        <Form<{
          name: string;
          description: string;
        }>
          ref={formRef}
          onFinish={(payload) => {
            if (isUpdateProps(props)) {
              updateItem({ payload, id: props.itemId });
            } else {
              addItem(payload);
              formRef.current?.resetFields();
            }
            afterFinish();
          }}
          initialValues={initalValues}
        >
          <Form.Item label="Name">
            <Form.Item name="name" noStyle>
              <Input />
            </Form.Item>
          </Form.Item>
          <Form.Item label="Beschreibung">
            <Form.Item name="description" noStyle>
              <Input.TextArea />
            </Form.Item>
          </Form.Item>
        </Form>
      }
    </Modal>
  );
};

export default MutateItemModal;
