import { Button, Modal, Space, Table, Typography } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useCharacterRequestsContext } from ".";
import { useState } from "react";
import MutateAcquaintanceModal from "../components/acquaintance-mutate-modal";

const CharacterAcquaintances = () => {
  const { useGetCharacterAcquaintances, useDeleteCharacterAcquaintance } =
    useCharacterRequestsContext();

  const { data: characterAcquaintances } = useGetCharacterAcquaintances();
  const { mutate: deleteItem } = useDeleteCharacterAcquaintance();

  const [acquaintanceIdToEdit, setAcquaintanceIdToEdit] = useState<
    string | undefined
  >(undefined);
  const [acquaintanceIdToDelete, setAcquaintanceIdToDelete] = useState<
    string | undefined
  >(undefined);
  const [addNewModalOpen, setNewModalOpen] = useState<boolean>(false);

  return (
    <>
      <Table dataSource={characterAcquaintances}>
        <Table.Column title="Name" dataIndex="name" />
        <Table.Column title="Beschreibung" dataIndex="description" />
        <Table.Column
          title="Aktionen"
          render={(item) => (
            <Space direction="horizontal">
              <Button
                type="primary"
                danger
                onClick={() => setAcquaintanceIdToDelete(item.id)}
              >
                <DeleteOutlined />
              </Button>
              <Button
                type="primary"
                onClick={() => setAcquaintanceIdToEdit(item.id)}
              >
                <EditOutlined />
              </Button>
            </Space>
          )}
        />
      </Table>
      <Space
        direction="horizontal"
        style={{
          right: 20,
          bottom: 10,
          zIndex: 100,
          position: "fixed",
        }}
      >
        <Button
          type="primary"
          shape="round"
          size="large"
          icon={<PlusOutlined />}
          onClick={() => setNewModalOpen(true)}
        >
          Bekanntschaft hinzufügen
        </Button>
      </Space>
      {addNewModalOpen && (
        <MutateAcquaintanceModal
          onCancel={() => setNewModalOpen(false)}
          afterFinish={() => setNewModalOpen(false)}
          mode="add"
        />
      )}
      {acquaintanceIdToEdit && (
        <MutateAcquaintanceModal
          afterFinish={() => setAcquaintanceIdToEdit(undefined)}
          onCancel={() => setAcquaintanceIdToEdit(undefined)}
          mode="edit"
          acquaintanceId={acquaintanceIdToEdit}
          initalValues={characterAcquaintances.find(
            (acquaintance: any) => acquaintance.id === acquaintanceIdToEdit
          )}
        />
      )}
      {acquaintanceIdToDelete && (
        <Modal
          title={"Item löschen"}
          open={acquaintanceIdToDelete !== undefined}
          onOk={() => {
            deleteItem(acquaintanceIdToDelete);
            setAcquaintanceIdToDelete(undefined);
          }}
          onCancel={() => setAcquaintanceIdToDelete(undefined)}
        >
          <Typography.Paragraph>
            {`Möchtest du ${
              characterAcquaintances?.find(
                (item: any) => item.id === acquaintanceIdToDelete
              )?.name || ""
            } löschen?`}
          </Typography.Paragraph>
        </Modal>
      )}
    </>
  );
};

export default CharacterAcquaintances;
