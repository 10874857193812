import { Button, Space } from "antd";
import { MonetaryAmount } from "../hooks/use-r4s-client";
import { PlusOutlined, MinusOutlined, DeleteOutlined } from "@ant-design/icons";
import { MoneyBagde } from "./money-badge";
import money from "../utils/money";
import { useCallback, useMemo } from "react";

export const MoneyInput: React.FC<{
  value: MonetaryAmount;
  setValue: (wallet: MonetaryAmount) => void;
  moneyInputButtonTypes?: Array<"reset" | number>;
  currency: keyof MonetaryAmount;
  disableNegative?: boolean;
}> = ({
  value: moneyInputValue,
  setValue: setMoneyInputValue,
  currency,
  moneyInputButtonTypes = [-100, -10, -1, "reset", 1, 10, 100],
  disableNegative,
}) => {
  const modifyCurrency = useCallback(
    (amount: number) => {
      const resultingMoney = money(moneyInputValue).transferObject({
        copper: 0,
        silver: 0,
        gold: 0,
        [currency]: amount,
      });
      if (disableNegative && resultingMoney.toCopper() < 0) {
        return;
      }
      setMoneyInputValue(resultingMoney.toObject());
    },
    [currency, disableNegative, moneyInputValue, setMoneyInputValue]
  );

  const resetCurrency = useCallback(
    () => setMoneyInputValue({ ...moneyInputValue, [currency]: 0 }),
    [currency, moneyInputValue, setMoneyInputValue]
  );

  const moneyInputButtons = useMemo(() => {
    return moneyInputButtonTypes.map((element) => {
      if (element === "reset") {
        return <Button onClick={resetCurrency} icon={<DeleteOutlined />} />;
      }

      const buttonContent = Math.abs(element) === 1 ? "" : Math.abs(element);
      const isPositiveValue = element > 0;
      const buttonIcon = isPositiveValue ? <PlusOutlined /> : <MinusOutlined />;
      const buttonOnClick = () => modifyCurrency(element);

      return (
        <Button onClick={buttonOnClick} icon={buttonIcon}>
          {buttonContent}
        </Button>
      );
    });
  }, [moneyInputButtonTypes, modifyCurrency, resetCurrency]);

  return (
    <>
      <Space direction="horizontal" align="center" size={15}>
        <MoneyBagde
          count={moneyInputValue[currency]}
          overflowCount={1_000_000}
          style={{ zIndex: 100 }}
          coinCurrency={currency}
        />
        <Button.Group>{moneyInputButtons}</Button.Group>
      </Space>
    </>
  );
};
