import { Form, FormInstance, Modal, Segmented } from "antd";
import { FC, useRef, useState } from "react";
import { useCharacterRequestsContext } from "../character";

type AddMagicStoneModalProps = {
  open: boolean;
  onCancel: CallableFunction;
  afterFinish: CallableFunction;
  initalValues?: { name: string; description: string };
};

const AddMagicStoneModal: FC<AddMagicStoneModalProps> = (props) => {
  const { open, onCancel, afterFinish, initalValues } = props;
  const { useAddMagicStone } = useCharacterRequestsContext();
  const { mutate: addMagicStone } = useAddMagicStone();
  const formRef = useRef<FormInstance>(null);
  const [magicStoneSize, setMagicStoneSize] = useState<
    "SMALL" | "MEDIUM" | "LARGE"
  >("SMALL");
  const [magicStoneType, setMagicStoneType] = useState<"TURBID" | "CLEAR">(
    "TURBID"
  );

  return (
    <Modal
      open={open}
      title="Magiestein hinzufügen"
      onCancel={() => {
        onCancel();
        formRef.current?.resetFields();
      }}
      onOk={() => {
        formRef.current?.submit();
      }}
    >
      {
        <Form<{
          name: string;
          description: string;
        }>
          ref={formRef}
          onFinish={(_payload) => {
            addMagicStone({
              size: magicStoneSize,
              type: magicStoneType,
              spellSlotsUsed: 0,
            });
            afterFinish();
          }}
          initialValues={initalValues}
        >
          <Form.Item label="Größe">
            <Form.Item name="size" noStyle>
              <Segmented
                options={[
                  { label: "Klein", value: "SMALL" },
                  { label: "Mittel", value: "MEDIUM" },
                  { label: "Groß", value: "LARGE" },
                ]}
                value={magicStoneSize}
                onChange={(value) => {
                  setMagicStoneSize(value as any);
                }}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item label="Typ">
            <Form.Item name="type" noStyle>
              <Segmented
                options={[
                  { label: "Trüb", value: "TURBID" },
                  { label: "Klar", value: "CLEAR" },
                ]}
                value={magicStoneType}
                onChange={(value) => {
                  setMagicStoneType(value as any);
                }}
              />
            </Form.Item>
          </Form.Item>
        </Form>
      }
    </Modal>
  );
};

export default AddMagicStoneModal;
